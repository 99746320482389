import React from 'react';
import {EvaluationActionFilter} from "./Filters/EvaluationActionFilter";
import {AssignTo} from "./Filters/AssignTo";
import {EvaluatorActionFilter} from "./Filters/EvaluatorActionFilter";
import {EvaluationCategoryActionFilter} from "./Filters/EvaluationCategoryFilter";
import {EvaluationRunFilter} from "./Filters/EvaluationRunFilter";
import BooleanComponent from "./Filters/BooleanComponent";

export const EvaluationForm=({action,
                                 evaluations,
                                 evaluationRuns,
                                 evaluators,
                                 loadingEvaluations,
                                 loadingEvaluators,
                                 onSelectEvaluation,
                                 onValidateChange,
                                 onSelectEvaluationRun,
                                 onSelectEvaluator,
                                 sampleSelfpacedData,
                                 onSelectEvaluationCategory,
                                 onSelectUser})=>{

    let spData={
        userId:"User Id"
    }
    if(action.assignStandAloneEvaluation()){
        spData = sampleSelfpacedData;
    }
    let evaluationCategory  = action.evaluationCategory !== 1 && <EvaluatorActionFilter
        action={action}
        key={"evaluatee"}
        evaluators={evaluators}
        loadingEvaluators={loadingEvaluators}
        onSelectEvaluator={onSelectEvaluator}
    />
    return(<div className={"evaluation-form"}>
        <EvaluationActionFilter
            action={action}
            evaluations={evaluations}
            onSelectEvaluation={onSelectEvaluation}
            loadingEvaluations={loadingEvaluations}
        />
        {action.assign360Evaluation() &&
            <EvaluationRunFilter
                action={action}
                evaluationRuns={evaluationRuns}
                onSelectEvaluationRun={onSelectEvaluationRun}
                loadingEvaluations={loadingEvaluations}
            />
        }
        {action.assign360Evaluation() &&
        <EvaluationCategoryActionFilter
          action={action}
          key={"evaluator"}
          onSelectEvaluationCategory={onSelectEvaluationCategory}
        />}

            {action.assign360Evaluation() && evaluationCategory}
        {(action.isSelfEvaluation()) &&
        <AssignTo
            action={action}
            assigneeLabel={"Evaluator :"}
            moduleName={"Evaluator"}
            loadingSelfPacedSample={false}
            sampleSelfpacedData={spData}
            onSelectUser={onSelectUser}
        />
        }
        {action.assign360Evaluation() && <AssignTo
            action={action}
            assigneeLabel={"Evaluatee :"}
            moduleName={"Evaluatee"}
            loadingSelfPacedSample={false}
            sampleSelfpacedData={spData}
            onSelectUser={onSelectUser}
        />}
        {action.assignStandAloneEvaluation() && <AssignTo
            action={action}
            assigneeLabel={"Evaluatee :"}
            moduleName={"evaluation"}
            loadingSelfPacedSample={false}
            sampleSelfpacedData={sampleSelfpacedData}
            onSelectUser={onSelectUser}
        />}
        <BooleanComponent checkboxValue={"Validate event criteria before executing this action"} value ={action.validateEventCriteria} onValidateChange={onValidateChange}/>
    </div> )
}