import React from 'react';
import Loader from 'react-loader-advanced';
import SelectSearch from 'react-select-search';
import * as Service from "../../../Service/Service";
import {AppButton} from "@disprz/components";

import { METRIC_TYPES,MetricTypes, MetricConditions } from '../../../Helpers/ComparisionFilterHelper';

class ComparisionFilters extends React.Component {
    constructor(props) {
        super(props);
        const metricObj =  (props.workflow.triggerFilters.metricTriggers 
                                && Object.keys(props.workflow.triggerFilters.metricTriggers).length) 
                                        ? props.workflow.triggerFilters.metricTriggers 
                                        : {
                                            "and": [],
                                            "or": []
                                        };
        const metricType = metricObj
                                ? (metricObj["and"].length > 0
                                        ? MetricTypes.find(m => m.numericValue === metricObj["and"][0].metricType).value
                                        : (metricObj["or"].length > 0
                                            ? MetricTypes.find(m => m.numericValue === metricObj["or"][0].metricType).value
                                            : METRIC_TYPES.QUANTITATIVE))
                                : METRIC_TYPES.QUANTITATIVE;
        this.state = {
            loadingMetrics: true,
            metrics: [],
            metricObj: metricObj,
            numberOfAndConditions: (metricObj["and"] ?  metricObj["and"].length : 1) || 1,
            numberOfOrConditions: (metricObj["or"] ?  metricObj["or"].length : 0),
            metricType: metricType,
        }
    }

    componentDidMount() {
        let metricsMicroServiceAPIUrl = sessionStorage.getItem("metricsMicroServiceAPIUrl");;
        if(metricsMicroServiceAPIUrl){
            Service.getAllMetricsMaster(metricsMicroServiceAPIUrl).then((data) => {
                let metricValues = [];
                let uniqMetricTypes = new Set();
                if (data && data.metricsList && data.metricsList.length) {
                    data.metricsList.forEach((metric) => {
                        uniqMetricTypes.add(metric.metricType);
                        metricValues.push({
                            ...metric,
                            name: metric.metricName,
                            value: metric.metricId.toString(),
                            valueString: `${metric.metricKey}$${metric.metricUnit}`
                        })
                    })
                }
                const anyQuantitativeMetric = uniqMetricTypes.has(MetricTypes.find(m => m.value === METRIC_TYPES.QUANTITATIVE).numericValue);
                const metricType = (anyQuantitativeMetric && this.state.metricType === METRIC_TYPES.QUANTITATIVE)
                                        ? METRIC_TYPES.QUANTITATIVE
                                        : METRIC_TYPES.QUALITATIVE;
                this.setState({
                    metrics: metricValues,
                    loadingMetrics: false,
                    metricType: metricType
                })
    
            });
        }
    }

    render() {
        if(this.state.loadingMetrics){
            return null;
        }
        let toRender = [];
        const {numberOfAndConditions, numberOfOrConditions} = this.state;
        for (let i = 0; i < numberOfAndConditions; i++) {
            toRender.push(
                <div key={`fieldEle_wrapper_And_${i}`} className={"field-wrapper"}>
                    {this.getRenderer(i, "and")}
                    {(i === numberOfAndConditions - 1) && this.props.enableMultipleConditions && <div className={"buttons"}>

                        <AppButton clickHandler={this.addConditions.bind(this, "and")} buttonIconCls={"corp-plus-icon"}
                                   buttonLabel={"And"}/>
                        {numberOfOrConditions === 0 &&
                        <AppButton clickHandler={this.addConditions.bind(this, "or")} buttonIconCls={"corp-plus-icon"}
                                   buttonLabel={"OR"}/>}
                    </div>}
                </div>);
        }
        for (let j = 0; j < numberOfOrConditions; j++) {
            toRender.push(
                <div key={`fieldEle_wrapper_OR_${j}`} className={"field-wrapper"}>
                    {j === 0 && <div className={"field-separator"}>OR</div>}
                    {this.getRenderer(j, "or")}
                    {(j === numberOfOrConditions - 1) && <div className={"buttons"}>
                        <AppButton clickHandler={this.addConditions.bind(this, "or")} buttonIconCls={"corp-plus-icon"}
                                   buttonLabel={"And"}/>
                    </div>}
                </div>);
        }
        if (this.props.workflow.isKPIValueAddTrigger()) {
            return (<div className={"comparision-filter"}>
                    {toRender}
                </div>
            )
        }
        return null;
    }

    removeConditions = (type, index) => {
        const {numberOfAndConditions, numberOfOrConditions} = this.state;
        let metricObj = this.state.metricObj;
        if( metricObj[type]){
            metricObj[type].splice(index,1);
        }

        if (type === "or") {
            if (numberOfOrConditions >= 1) {
                let toOrAdd = numberOfOrConditions - 1;
                this.setState({
                    numberOfOrConditions: toOrAdd,
                    metricObj
                })
            }
        }
        else {
            if (numberOfAndConditions >= 1) {
                let toRemove = numberOfAndConditions - 1;
                this.setState({
                    numberOfAndConditions: toRemove,
                    metricObj
                })
            }
        }
    }
    getMetricsArray = () => {
        return this.state.metricObj;
    }
    setMetricType = (selectedValue)=>{
        this.setState({
          metricObj:{
                  and: [],
                  or: [],
                },
          metricType: selectedValue.value,
        },()=>this.checkIsMandatoryFieldFilled());
    }
    setMetricsArray = (type, index, selectedValue) => {
        let metricObj = this.state.metricObj;
        let selectedTypeArray = metricObj[type];
        if ((selectedTypeArray && selectedTypeArray.length === 0)||(!selectedTypeArray[index])) {
            selectedTypeArray.push({
                metricKey: this.state.metrics.find(metric=>metric.value===selectedValue.value).valueString,
                condition: null,
                value: null,
                metricOptionValue:null,
                metricType:MetricTypes.find(type=>type.value===this.state.metricType).numericValue
            })
        }
        else {
            let currentArray = selectedTypeArray[index];
            currentArray.metricKey = this.state.metrics.find(metric=>metric.value===selectedValue.value).valueString;
            currentArray.condition = null
            currentArray.value = null
            currentArray.metricOptionValue = null
        }
        metricObj[type] = selectedTypeArray;
        this.setState({
            metricObj
        },()=>this.checkIsMandatoryFieldFilled())
    }
    setCondition = (type, index, selectedValue) => {
        let metricObj = this.state.metricObj;
        let selectedTypeArray = metricObj[type];
        if (selectedTypeArray.length === 0 || (!selectedTypeArray[index])) {
            selectedTypeArray.push({
                metricKey: null,
                condition: MetricConditions.find(condition=>condition.value===selectedValue.value)?.numericValue,
                value: null,
                metricOptionValue:null,
                metricType:MetricTypes.find(type=>type.value===this.state.metricType).numericValue
            })
        }
        else {
            let currentArray = selectedTypeArray[index];
            currentArray.condition = MetricConditions.find(condition=>condition.value===selectedValue.value)?.numericValue;
        }
        metricObj[type] = selectedTypeArray;
        this.setState({
            metricObj
        },()=>this.checkIsMandatoryFieldFilled())
    };
    setValue = (type, index, e) => {
        const value= e.target.value;
        const validNumber = /^[-]?\d*[.]?\d?\d?$/.test(value);
        if(value != "" &&  !validNumber){
            return;
        }

        let metricObj = this.state.metricObj;
        let selectedTypeArray = metricObj[type];
        if (selectedTypeArray.length === 0 || (!selectedTypeArray[index])) {
            selectedTypeArray.push({
                metricKey: null,
                condition: null,
                selectedCondition: null,
                value: value,
                metricOptionValue:null,
                metricType:MetricTypes.find(type=>type.value===this.state.metricType).numericValue
            })
        }
        else {
            let currentArray = selectedTypeArray[index];
            currentArray.value = value;
        }
        metricObj[type] = selectedTypeArray;
        this.setState({
            metricObj
        },()=>this.checkIsMandatoryFieldFilled())
    }
    setThresholds = (type, index, e) =>{
        let metricObj = this.state.metricObj;
        let selectedTypeArray = metricObj[type];
        if (selectedTypeArray.length === 0 || (!selectedTypeArray[index])) {
            selectedTypeArray.push({
                metricKey: null,
                condition: null,
                selectedCondition: null,
                value: null,
                metricOptionValue:e.numericValue,
                metricType:MetricTypes.find(type=>type.value===this.state.metricType).numericValue
            })
        }
        else {
            let currentArray = selectedTypeArray[index];
            currentArray.metricOptionValue = e.numericValue;
        }
        metricObj[type] = selectedTypeArray;
        this.setState({
            metricObj
        },()=>this.checkIsMandatoryFieldFilled())
    }
    addConditions = (type) => {
        const {numberOfAndConditions, numberOfOrConditions} = this.state;
        if (type === "or") {
            let toOrAdd = numberOfOrConditions + 1;
            this.setState({
                numberOfOrConditions: toOrAdd
            })
        }
        else {
            let toAdd = numberOfAndConditions + 1;
            this.setState({
                numberOfAndConditions: toAdd
            })
        }

    }
    getMetrics = (metricType) =>{
        return this.state.metrics.filter(
          (metric) =>
            metric.metricType ===
            MetricTypes.find((type) => type.value === metricType)?.numericValue
        );
    }
    getMetricConditions=(metricType)=>{
        return MetricConditions.filter((metric) =>
          metric.numericValues.includes(
            MetricTypes.find((type) => type.value === metricType)?.numericValue
          )
        );
    }
    getMetricThresholds=(metricKey)=>{
        let thresholds= this.state.metrics.find(metric=>metric.valueString === metricKey)?.metricOptions;
        thresholds=thresholds?JSON.parse(thresholds):[];
        let thresholdOptions=thresholds&&thresholds.map(option => {
            return {
              value: option.optionId.toString(),
              name: option.optionName,
              numericValue:option.optionId
            };
          });
        return thresholdOptions;
    }
    getMetricTypes=()=>{
        let currentMetricTypes=[...new Set(this.state.metrics.map(metric=>metric.metricType))];
        return MetricTypes.filter(type=>currentMetricTypes.includes(type.numericValue));
    }
    getMandatoryIcon=()=>{
        return (<small className={'mandatory'}>*</small>)
    }
    getRenderer = (i, type) => {
        const {metricObj, loadingMetrics,metricType} = this.state;
        let currentMetricObject=(metricObj[type] && metricObj[type][i])|| {};
        let currentMetrics =this.getMetrics(metricType);
        let currentMetricTypes = this.getMetricTypes();
        let clsName = type === "and" ? "and" : "or";
        const disableAll = currentMetricTypes.length === 0;
        const componentCls = "component"+(currentMetrics.length  === 0?" ele-disabled":"")
        return (<div className={`form-field field-row ${clsName}`}>
            <div className={"field-ele field-medium"}>
                <div className="label"> KPI :</div>
                <div className={"component"+(currentMetricTypes.length<2?" ele-disabled":"")}>
                    <Loader show={loadingMetrics} message={'fetching...'}>
                        <SelectSearch
                                      options={currentMetricTypes}
                                      onChange={this.setMetricType.bind(this)}
                                      value={disableAll ? null: metricType}
                                      placeholder="Select metric type"/>
                    </Loader>
                </div>
            </div>
            <div className={"field-ele"}>
                <div className="label"> Metrics : {this.getMandatoryIcon()}</div>
                <div className={componentCls}>
                    <Loader show={loadingMetrics} message={'fetching...'}>
                        <SelectSearch options={currentMetrics}
                                      onChange={this.setMetricsArray.bind(this, type, i)}
                                      value={(currentMetricObject && currentMetricObject.metricKey && currentMetrics?currentMetrics.find(metric=>metric.valueString===currentMetricObject.metricKey)?.value :'')||''}
                                      placeholder="Select Metric"/>
                    </Loader>
                </div>
            </div>
            <div className={"field-ele"}>
                <div className="label"> Condition : {this.getMandatoryIcon()}</div>
                <div className={componentCls}>
                    <Loader show={loadingMetrics} message={'fetching...'}>
                        <SelectSearch options={this.getMetricConditions(metricType)}
                                      onChange={this.setCondition.bind(this, type, i)}
                                      value={currentMetricObject?MetricConditions.find(condition=>condition.numericValue===currentMetricObject.condition)?.value: ''}
                                      placeholder="Choose condition"/>
                    </Loader>
                </div>
            </div>
            {metricType===METRIC_TYPES.QUALITATIVE?(<div className={"field-ele"}>
                <div className="label"> Threshold : {this.getMandatoryIcon()}</div>
                <div className={componentCls}>
                    <Loader show={loadingMetrics} message={'fetching...'}>
                        <SelectSearch options={this.getMetricThresholds(currentMetricObject?.metricKey)}
                                      onChange={this.setThresholds.bind(this, type, i)}
                                      value={currentMetricObject?.metricOptionValue?.toString() ||''}
                                      placeholder="Choose Threshold"/>
                    </Loader>
                </div>
            </div>):(
            <div className={"field-ele field-small"}>
                <div className="label"> Value : {this.getMandatoryIcon()}</div>
                <div className={componentCls}>
                    <Loader show={loadingMetrics} message={'fetching...'}>
                        <input className={"disprz-input"}
                               value={currentMetricObject?.value || ''}
                               onChange={this.setValue.bind(this, type, i)}
                               type={"text"}/>
                    </Loader>
                </div>
            </div>
            )}
            {(i >= 1 || type === "or") &&
            <AppButton buttonCls={"close-icon"} clickHandler={this.removeConditions.bind(this, type, i)}
                       buttonIconCls={"corp-close-icon"}/>}
        </div>)
    }

    checkIsMandatoryFieldFilled = () =>{
        const metricValue = ((this.state.metricObj["and"].length>0 && this.state.metricObj["and"][0]) 
                                || (this.state.metricObj["or"].length>0 && this.state.metricObj["or"][0])
                                ) || {}
        const {metricKey = '', condition = '', value = '', metricOptionValue =''} = metricValue
        let disableSave = !metricKey || !condition
        switch(this.state.metricType)
        {
            case METRIC_TYPES.QUANTITATIVE:
                disableSave = disableSave || !value                
                break                
            case METRIC_TYPES.QUALITATIVE:
                disableSave = disableSave || !metricOptionValue                
                break
        }
        this.props.onInitKpiValue(disableSave)
    }
    
}
export default ComparisionFilters
