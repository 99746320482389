import React from 'react';
import SelectSearch from 'react-select-search';
import Loader from 'react-loader-advanced';

export const EvaluationRunFilter = ({action,evaluationRuns, loadingEvaluations, onSelectEvaluationRun}) => {
    let evaluationRunOptions = [];
    if (evaluationRuns.length === 0) {
        evaluationRunOptions.push({
            name: "select evaluation",
            value: 1
        })
    }
    else {
        evaluationRuns.forEach((evaluationRun) => {
            evaluationRunOptions.push({
                name: evaluationRun.evaluationRunDescription,
                value: evaluationRun.evaluationRunId
            });

        });
    }
    let evaluationName =   "Evaluation Run Name: (optional)";
    let evaluationDescription =   "The evaluation run to be choosen for an evaluation";

    return( <div className={" form-field"}>
        <div className={"label"}>{evaluationName}</div>
        <div className={"component"} >
            <Loader show={loadingEvaluations} message={'fetching...'}>
                <SelectSearch onChange={onSelectEvaluationRun} options={evaluationRunOptions}
                              value={parseInt(action.evaluationRunId)}
                              placeholder="Select Feedback"/>
                <small>{evaluationDescription}</small>
            </Loader>
        </div>
    </div>);
}