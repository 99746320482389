import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import BooleanComponent from "./Filters/BooleanComponent";

export const EmailForm=({action,
                            validEmail,
                            editorState,
                            onAddressChange,
                            onSubjectChange,
                            onValidateChange,
                            onEditorStateChange})=>{
    let emailClass = validEmail ? "" : "error";
    let userEmail = "{{email}}";
    let managerEmail = "{{reporting_Manager_Email}}";
    let firstName = "{{firstName}}";
    let lastName = "{{lastName}}";
    let moduleexpName = "{{moduleName}}";
    let skillName = "{{skillName}}"
    let programName = "{{programName}}"
    return(
        <div className={" email"}>
            <div className={" form-field"}>
                <div className={"label"}>To :</div>
                <div className={"component"}>
                    <input type={"email"} className={emailClass} value={action.emailData.toAddress} onChange={onAddressChange}
                           name={"addressInput"}/>
                    <small>Who will this email be sent to? separate multiple email addresses in one field with a comma eg. test@example.com,example@example.com.
                        Use expressions to evaluate from trigger like <strong>{userEmail}</strong> for the user email who satisfies the above trigger,  <strong>{managerEmail}</strong>  -
                        for the manager email whom reports to.
                    </small>
                </div>
            </div>
            <div className={" form-field"}>
                <div className={"label"}>Subject :</div>
                <div className={"component"}>
                    <input type={"text"} value={action.emailData.subject} onChange={onSubjectChange}
                           name={"subjectInput"}/>
                </div>
            </div>
            <div className={" form-field"}>
                <div className={"label"}>Body :</div>
                <div className={"component"}>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                    />
                    <small>The content of the email.
                        Use expressions to evaluate from trigger like <strong>{firstName}</strong> for the user first name,  <strong>{lastName}</strong>  -
                        for the user last name, <strong>{programName}</strong> for program name, <strong>{skillName}</strong> for skill name and <strong>{moduleexpName}</strong> for the module name.
                    </small>
                </div>
            </div>
            <BooleanComponent checkboxValue={"Validate event criteria before executing this action"} value ={action.validateEventCriteria} onValidateChange={onValidateChange}/>
        </div>
    )
}