import React from 'react';
import SelectSearch from 'react-select-search';
import {ParamsData} from "./Filters/ParamsData";
import BooleanComponent from "./Filters/BooleanComponent";

export const WebhookForm = ({
                                action,
                                loadingSelfPacedSample,
                                sampleSelfpacedData,
                                onMethodChange,
                                onValidateChange,
                                onSelectParamsData,
                                onUrlChange
                            }) => {

    let methodOptions = [{
        name: "Get",
        value: "get"
    }, {
        name: "Post",
        value: "post"
    }]

    return (<div className={"webhook-form"}>
        <div className={" form-field row-field col-md-12"}>
            <div className={"col-md-3"}>
                <div className={"label"}>
                    Method:
                </div>
                <div className={"component"}>
                    <SelectSearch onChange={onMethodChange} options={methodOptions}
                                  value={action.webhook.method}/>
                </div>
            </div>
            <div className={"col-md-9"}>
                <div className={"label"}>
                    Webhook url:
                </div>
                <div className={"component"}>
                    <input className={"url"} type={"text"} value={action.webhook.webhookUrl} onChange={onUrlChange}/>
                </div>
            </div>

        </div>
        <div className={" form-field"}>
             <ParamsData
             action={action}
             onSelectParamsData={onSelectParamsData}
             paramsLabel={action.webhook.method === "get" ? "Query params" : "Post Data"}
             loadingSelfPacedSample={loadingSelfPacedSample}
             sampleSelfpacedData={sampleSelfpacedData}
             />
        </div>
        <BooleanComponent checkboxValue={"Validate event criteria before executing this action"} value ={action.validateEventCriteria} onValidateChange={onValidateChange}/>
    </div>);
}