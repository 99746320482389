export const METRIC_TYPES={
    QUANTITATIVE:"Quantitative",
    QUALITATIVE:"Qualitative"
}
export const MetricTypes = [
    { name: METRIC_TYPES.QUANTITATIVE, value: METRIC_TYPES.QUANTITATIVE,numericValue:1 },
    { name: METRIC_TYPES.QUALITATIVE, value: METRIC_TYPES.QUALITATIVE,numericValue:2 },
];

export const MetricConditions = [
  { value: "LESS_THAN", name: "less than",numericValues:[1],numericValue:2 },
  { value: "EQUAL_TO", name: "equal to",numericValues:[1,2] ,numericValue:3},
  { value: "NOT_EQUAL_TO", name: "not equal to",numericValues:[2],numericValue:4 },
  { value: "GREATER_THAN", name: "greater than",numericValues:[1],numericValue:1 },
];