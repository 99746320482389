import React from 'react';
import Loader from 'react-loader-advanced';
import MultiSelect from "@khanacademy/react-multi-select";

export const EvaluatorActionFilter = ({action,evaluators, loadingEvaluators, onSelectEvaluator}) => {
    let evaluatorOptions = [];
    if (evaluators.length === 0) {
        evaluatorOptions.push({
            label: "select evaluation",
            value: 1
        })
    }
    else {
        evaluators.forEach((manager) => {
            evaluatorOptions.push({
                label: manager.managerTypeName,
                value: parseInt(manager.managerTypeId)
            });

        });
    }

    return( <div className={" form-field"}>
        <div className={"label"}>Evaluator :</div>
        <div className={"component"} disabled={!action.evaluationId}>
            <Loader show={loadingEvaluators} message={'fetching...'}>
                <MultiSelect
                    overrideStrings={
                        {
                            selectSomeItems: "Select Evaluator",
                            allItemsAreSelected: "All Users are Selected",
                            selectAll: "Select All Users",
                            search: "Search User",
                        }
                    }
                    disabled={!action.evaluationId}
                    searchPlaceholder={"Select Modules"}
                    options={evaluatorOptions}
                    selected={action.evaluators || []}
                    onSelectedChanged={onSelectEvaluator}
                />
                <small>The evaluator for the feedback to be assigned.</small>
            </Loader>
        </div>
    </div>);
}