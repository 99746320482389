import React from 'react';
import SelectSearch from 'react-select-search';
import Trigger from "./TriggerComponent";
import ActionComponent from "./ActionComponent";
import * as CommonHelper from "../Helpers/CommonHelper"
import Actions from "../Models/Actions";


class FormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentWorkflow: this.props.currentWorkflow,
            originalWorkflow: Object.assign({},this.props.currentWorkflow),
            displayFooterText: false,
            loadData: this.props.loadData,
            actionToShow:null,
            hideTrigger: this.props.currentWorkflow.actions.length > 0
        }
        this.triggerNames = CommonHelper.getTriggers();
        this.actionNames = CommonHelper.getActions();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentWorkflow.id !== this.props.currentWorkflow.id) {
            this.setState({
                currentWorkflow: nextProps.currentWorkflow,
                originalWorkflow: Object.assign({}, nextProps.currentWorkflow),
                loadData: nextProps.loadData,
                displayFooterText: false
            });
        }
        else {
            this.setState({
                currentWorkflow: nextProps.currentWorkflow,
                originalWorkflow: Object.assign({},nextProps.currentWorkflow),
                loadData: nextProps.loadData
            })
        }
    }

    render() {
        let arrowClass = "fa-chevron-up";
        let triggerComponentClass = "show";
        let addMoreAction = "";

        if (this.state.hideTrigger) {
            arrowClass = "fa-chevron-down";
            triggerComponentClass = "hide";
        }
        let triggerOptions = [];
        this.triggerNames.forEach((trigger) => {
            triggerOptions.push({
                name: trigger.triggerName,
                value: trigger.triggerValue,
                helpText:trigger.helpText
            })
        });
        let actions = [],  actionFooter = "";
        if (this.state.currentWorkflow.actions.length && this.state.displayFooterText) {
            actionFooter = (<div className={"action-footer"}>
                Please select one more action to complete workflow
            </div>)
        }

        this.state.currentWorkflow.actions.forEach((action, index) => {
            let i = index  + 1;
            let actionOptions = [];
            let actionComponentClass = index === this.state.actionToShow ? "" :"hide";
            let currentActions = this.state.currentWorkflow.actions;
            let skipDelay = currentActions.length > 1 && index > 0 && currentActions[index - 1].assignDelay();
            this.actionNames.forEach((action) => {
                if(!(skipDelay && action.actionValue === "delay")){
                    actionOptions.push({
                        name: action.actionName,
                        value: action.actionValue,
                        helpText:action.helpText
                    })
                }
            });
            let flowArrow = (
                <div key={"arrow " + i} className={"action-component-wrapper"}>
                    <div className={"arrow-wrapper"}>
                        <div className={"fa-arrow-down fa"}></div>
                    </div>
                    <div className={"action"}>
                        <div ref={"actionHeader_" + i} className={"action-header " + actionComponentClass}>
                            <div className={"title"}><span className={"prefix"}>Do this...</span>
                                <div className={"form-control"}>
                                    <div className="component trigger-select">
                                        <SelectSearch onChange={this.setAction.bind(this, i, false)}
                                                      options={actionOptions}
                                                      renderOption={this.renderTriggerOptions}
                                                      value={action.actionName}
                                                      placeholder="Select Action"/>
                                    </div>
                                </div>
                            </div>
                            { index !== this.state.actionToShow && <div className={"closehandle"} onClick={this.toggleAction.bind(this, i, true)}>
                                <div  className={"arrow fa fa-chevron-down"}/>
                            </div>}
                            { index === this.state.actionToShow &&  <div className={"closehandle"} onClick={this.toggleAction.bind(this, i, false)}>
                                <div  className={"arrow fa fa-chevron-up"}/>
                            </div>}
                        </div>
                        <div ref={"actionComponent_" + i} className={"action-component " + actionComponentClass}>
                            <ActionComponent onUpdateAction={this.updateAction} onDelete={this.onDeleteAction}
                                     loadData={this.state.loadData}
                                     onDone={this.toggleAction.bind(this, i, true)} currentAction={action}
                                     currentActionIndex={i}/>
                        </div>
                    </div>
                    {(i === this.state.currentWorkflow.actions.length && actionFooter)}
                </div>)
            actions.push(flowArrow);
        }, this);
        return (
            <div className={"workflow-control"}>
                {addMoreAction}
                <div className={"trigger"}>
                    <div className={"trigger-header " + triggerComponentClass}>
                        <div className={"title"}><span className={"prefix"}>When this happens...</span>
                            <div className={"form-control"} onClick={this.preventDefault}>
                                <div className="component trigger-select">
                                    <SelectSearch onChange={this.setTrigger} options={triggerOptions}
                                                  renderOption={this.renderTriggerOptions}
                                                  value={this.state.currentWorkflow.trigger}
                                                  placeholder="Select Trigger"/>
                                </div>
                            </div>
                        </div>
                        <div className={"closehandle"} onClick={this.toggleTrigger}>
                            <div className={"arrow fa " + arrowClass}></div>
                        </div>
                    </div>
                    <div className={"trigger-component " + triggerComponentClass}>
                        <Trigger onSelectedTrigger={this.setTriggerName} onContinue={this.onContinue}
                                 loadData={this.state.loadData}
                                 currentWorkflow={this.props.currentWorkflow}/>
                    </div>
                </div>
                <div className={"actions"}>
                    {actions}

                </div>
            </div>)
    }

    renderTriggerOptions = function (option) {
        return (<div>
            <div className={"select-text"}>{option.name}</div>
            <small className={"help-text"}>{option.helpText}</small>
        </div>)
    }
    setTrigger = (selectedTrigger) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.trigger = selectedTrigger.value;
        currentWorkflow.triggerName = selectedTrigger.name;
        if(currentWorkflow.id === this.state.originalWorkflow.id && currentWorkflow.trigger === this.state.originalWorkflow.trigger){
            currentWorkflow.triggerFilters = this.state.originalWorkflow.triggerFilters;
        }
        else{
            currentWorkflow.triggerFilters = {
                "moduleIds": [],
                "skillIds": [],
                "journeyIds": [],
                "programIds": [],
                "evaluationIds": [],
                "fieldId1": "",
                "fieldValue1": [],
                "fieldId2": "",
                "fieldValue2": [],
                "fieldId3": "",
                "fieldValue3": [],
            };
        }

        currentWorkflow.actions = [];

        this.setState({
            currentWorkflow: currentWorkflow,
            loadData: true,
            hideTrigger: false
        }, () => {
            // this.props.onSelectedTrigger(this.state.currentWorkflow)


        })
    }
    setAction = (index, x, selectedAction) => {
        let currentWorkflow = this.state.currentWorkflow;
        let currentAction = currentWorkflow.actions[index - 1];
        currentAction.actionName = selectedAction.value;
        currentAction.actionDisplayName = selectedAction.name;

        //  if(actionName == "assignselfpacedmodule"){
        currentAction.delayFor = 0;
        currentAction.selectedProgram = 0;
        currentAction.selectedSkill = 0;
        currentAction.journeyId = 0;
        currentAction.emailData = {
            "toAddress": "",
            "subject": "",
            "body": ""
        }
        currentAction.moduleId = 0;
        currentAction.journeyId = 0;
        currentAction.evaluationId = 0;
        currentAction.evaluators =[];
        currentAction.userId = "";

        //  }
        // else if(actionName == "sendmail" || actionName == "delay") {
        //   currentAction.moduleId = 0;
        //   currentAction.journeyId =0;
        //   currentAction.userId = "";
        //
        // }
        this.setState({
            currentWorkflow,
            actionToShow:index - 1
        }, function () {

        })

    }
    updateAction = (currentAction, index) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.actions[index - 1] = currentAction;
        this.setState({
            currentWorkflow: currentWorkflow
        })
    }
    onDeleteAction = (actionIndex) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.actions.splice(actionIndex - 1, 1);
        let save = true;
        if( currentWorkflow.actions.length){
            save =   !currentWorkflow.actions[this.state.currentWorkflow.actions.length - 1].assignDelay()
        }

        this.setState({
            currrentWorkflow: currentWorkflow,
            displayFooterText: !save,
        }, function () {
            this.props.onActionPush(save);
        })
    }
    showAction = (actionIndex) => {
        this.setState({
            actionToShow:actionIndex -1
        })
    }
    toggleAction = (actionIndex, show) => {
        let save = true;
        let toggleIndex = null;
        if(show){
            toggleIndex = actionIndex -1;
        }
        if (this.state.currentWorkflow.actions.length) {
            save = !this.state.currentWorkflow.actions[this.state.currentWorkflow.actions.length - 1].assignDelay()
        }
        this.setState({
            displayFooterText: !save,
            loadData: false,
            actionToShow:toggleIndex
        }, () => {
            this.props.onActionChange(save);
        })

    }


    onContinue = () => {
        this.toggleTrigger();
        if (this.state.currentWorkflow.actions.length === 0) {
            let currentWorkflow = this.state.currentWorkflow;
            currentWorkflow.actions.push(new Actions({
                "actionName": "assignSelfpacedModule",
                "selectedProgram": 0,
                "selectedSkill": 0,
                "actionDisplayName": "Assign SelfPaced Module",
                "delayFor": 1,
                "evaluationRunId": 0,
                "delayUnit": 1,
                "emailData": {
                    "toAddress": "",
                    "subject": "",
                    "body": ""
                },
                "moduleId": 0,
                "userId": 0,
                "journeyId": 0
            }));

            this.setState({
                currentWorkflow: currentWorkflow,
                loadData: true
            }, function () {
                this.props.onActionPush();
                this.showAction(this.state.currentWorkflow.actions.length);
            })
        }
    }

    toggleTrigger = () => {
        this.setState({
            hideTrigger: !this.state.hideTrigger,
            loadData: false
        });
    }
    setTriggerName = (currentWorkflow) => {
        this.setState({currentWorkflow: currentWorkflow});
    }


}

export default FormComponent