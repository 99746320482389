import React from 'react';
import SelectSearch from 'react-select-search';
import {TimePicker} from "@disprz/components";


export const DelayForm = ({action, onDelayMinutesChanged, onDelayUnitChanged, onDelayChanged,onDelayTimeSelect}) => {
    let units = [{
        name: "Minutes",
        value: 1
    }, {
        name: "Hours",
        value: 2
    }, {
        name: "Days",
        value: 3
    }, {
        name: "Weeks",
        value: 4
    }]
    let inputControl = "";
    if (action.delayUnit === 1) {
        let minutesOption = [
            {
                name: "1",
                value: 1
            },
            {
                name: "15",
                value: 15
            },
            {
                name: "30",
                value: 30
            },
            {
                name: "45",
                value: 45
            }
        ]
        inputControl = (<SelectSearch onChange={onDelayMinutesChanged} options={minutesOption}
                                      value={action.delayFor}
                                      placeholder="Select Value"/>)
    }
    else {
        inputControl = (<input type={"number"} min={1} value={action.delayFor} onChange={onDelayChanged}
                               name={"delayinput"}/>);
    }
    let formControl = (<div className={"delay-wrapper"}>
        <div className={"form-field"}>
            <div className={"component"}>
                <div className={"label"}>Delay For :</div>
                {inputControl}
            </div>
            <div className={"component"}>
                <div className={"label"}>Unit :</div>
                <SelectSearch onChange={onDelayUnitChanged} options={units}
                              value={parseInt(action.delayUnit)}
                              placeholder="Select Unit"/>
            </div>
        </div>
        {(action.delayUnit === 3 || action.delayUnit === 4) && <div className={"form-field execute"}>
            <div className={"component"}>
                <div className={"label"}>Execute at : <small>(optional)</small></div>
                <div className={"dropdownwrapper"}>
                 <TimePicker timeInterval={15} defaultValue={action.delayExecuteAt ? JSON.parse(action.delayExecuteAt):null} onSelect={onDelayTimeSelect}/>
                    <span className={"sub-label"}>(hrs)</span>
                </div>
            </div>
        </div>}
    </div>)

    return formControl;
}
