import React from 'react';
import MultiSelect from "@khanacademy/react-multi-select";
import Loader from 'react-loader-advanced';


export const ModuleFilters = ({workflow, modules, loadingModules, onModuleSelect}) => {
    if (workflow.isJourneyTrigger() || workflow.isUserCreatedTrigger() || workflow.isUserAttributeChangeTrigger() || workflow.isKPIValueAddTrigger() || workflow.isStandaloneSurveyCompletedTrigger() || workflow.is360EvaluationCompletedTrigger() || workflow.isUserCompletesTenureTrigger()) {
        return (null)
    }
    else {
        let modulesOptions = [];
         modules.forEach((module) => {
            if(workflow.triggerFilters.moduleIds.includes(module.id)){
                modulesOptions.splice(0,0,{
                    label: module.moduleName,
                    value: module.id
                })
            }
            else{
                modulesOptions.push({
                    label: module.moduleName,
                    value: module.id
                });
            }


        });

        return (<div className={"form-field"}>
            <div className="label"> Modules : <small>(Optional)</small></div>
            <div className="component">
                <Loader show={loadingModules} message={'fetching...'}>
                    <MultiSelect
                        overrideStrings={
                            {
                                selectSomeItems: "Select Modules",
                                allItemsAreSelected: "All Modules are Selected",
                                selectAll: "Select All Modules",
                                search: "Search Modules",
                            }
                        }
                        disabled={!workflow.triggerFilters.skillIds.length}
                        searchPlaceholder={"Select Modules"}
                        options={modulesOptions}
                        selected={workflow.triggerFilters.moduleIds}
                        onSelectedChanged={onModuleSelect}
                    />
                    <small>Select the Module the trigger applies to. If this is left empty, the trigger will apply to all modules meeting the above Program Category, Program and Skill conditions.
                    </small>
                </Loader>
            </div>
        </div>)
    }
}
