import React from 'react';
import MultiSelect from "@khanacademy/react-multi-select";
import Loader from 'react-loader-advanced';


export const ProgramFilters = ({workflow, programs, loadingPrograms, onProgramSelect}) => {
    if (workflow.isJourneyTrigger() || workflow.isUserCreatedTrigger() || workflow.isUserAttributeChangeTrigger() || workflow.isKPIValueAddTrigger() || workflow.isStandaloneSurveyCompletedTrigger() || workflow.is360EvaluationCompletedTrigger() || workflow.isUserCompletesTenureTrigger()) {
        return (null)
    }
    else {
        let programOptions = [];
        programs.forEach((program) => {
            if (workflow.triggerFilters.programIds.includes(program.id)) {
                programOptions.splice(0, 0, {
                    label: program.programName,
                    value: program.id
                })
            }
            else {
                programOptions.push({
                    label: program.programName,
                    value: program.id
                })
            }

        }, this);

        return (<div className={"form-field"}>
            <div className="label"> Programs : <small>(Optional)</small></div>
            <div className="component">
                <Loader show={loadingPrograms} message={'fetching...'}>
                    <MultiSelect
                        overrideStrings={
                            {
                                selectSomeItems: "Select Programs",
                                allItemsAreSelected: "All Programs are Selected",
                                selectAll: "Select All Programs",
                                search: "Search Programs",
                            }
                        }
                        searchPlaceholder={"Select Programs"}
                        options={programOptions}
                        selected={workflow.triggerFilters.programIds}
                        onSelectedChanged={onProgramSelect}
                    />
                    <small>Filter by the Program that the Module belongs to (e.g., Data Science). Multiple values work
                        like an OR condition.
                    </small>
                </Loader>
            </div>
        </div>)
    }
}
