import React from 'react';
import {AppButton, AppIcon} from "@disprz/components"

class TabbedComponent extends React.Component{

    render(){
        let tabsHtml = [], tabIcon = "", iconExist = false, tabItemWidth = 100 / this.props.tabs.length;
        this.props.tabs.forEach( (tabConfig, i) =>{
            var tabItemCls = "tab-item " + (tabConfig.ctrCls || "");
            if (tabConfig.active === true) {
                tabItemCls += " active-tab";
            }
            var onTabSelection = this.triggerTabSelection;
            if (tabConfig.disabled === true) {
                tabItemCls += " disabled-tab";
                onTabSelection = "";
            }
            if (tabConfig.iconCls !== "" && tabConfig.iconCls) {
                tabIcon = <AppIcon iconCls={tabConfig.iconCls} ctrCls="tab-item-icon"/>;
                iconExist = true;
            }
            var closeBtn = "";
            if (typeof tabConfig.isClosable !== "undefined" && tabConfig.isClosable) {
                closeBtn = <AppButton
                    clickHandler={ (e) => {
                        e.stopPropagation();
                        this.onClose(tabConfig.data);
                    }}
                    buttonCls="cancel-button"
                    buttonIconCls="icon-close-delete"/>;
            }

            tabsHtml.push(
                <div key={i + 1} style={{width: tabItemWidth + "%"}} className={tabItemCls} data-tabindex={i}
                     onClick={onTabSelection} title={tabConfig.title}>
                    <div className={"tab-item-text text-center"}>
                        {tabIcon}
                        <span className={!iconExist?"tab-item-text":""}>{tabConfig.name}</span>
                        <div className={"badge"}>{tabConfig.badgeCount}</div>
                    </div>
                    {closeBtn}
                </div>);

        });
        let addWorkFlowButton = (<AppButton
            clickHandler={ (e) => {
                e.stopPropagation();
                this.props.addNewWorkflow();
            }}
            buttonLabel={"Add Workflow"}
            buttonCls="add-workflow-button"
            buttonIconCls="icon-close-delete"/> );
        return (
            <div className={"tabs-container " + this.props.ctrCls}>
                {tabsHtml}
                {addWorkFlowButton}
            </div>
        );
    }
    onClose =(tabNumber) => {
        this.props.onClose(tabNumber);
    };
    triggerTabSelection = (e) => {
        e.stopPropagation();
        var tabIndex = parseInt(e.currentTarget.getAttribute("data-tabindex"));
        var tabConfig = this.props.tabs[tabIndex];

        this.props.onTabSelection(tabIndex + 1, tabConfig.data || null);
    };
}

export default  TabbedComponent
