import React from 'react';
import MultiSelect from "@khanacademy/react-multi-select";
import Loader from 'react-loader-advanced';


export const JourneyFilters = ({workflow, journeys, loadingJourneys, onSelectJourney}) => {
    if (!workflow.isJourneyTrigger() ) {
        return (null)
    }
    else {
        let journeyOptions = [];
        journeys.forEach((journey) => {
            if(workflow.triggerFilters.journeyIds.includes(journey.id)){
                journeyOptions.splice(0,0,{
                    label: journey.journeyName,
                    value: journey.journeyId
                })
            }
            else{
                journeyOptions.push({
                    label: journey.journeyName,
                    value: journey.journeyId
                })
            }
        })

        return (<div className={"form-field"}>
            <div className="label"> Journeys : <small>(Optional)</small></div>
            <div className="component">
                <Loader show={loadingJourneys} message={'fetching...'}>
                    <MultiSelect
                        overrideStrings={
                            {
                                selectSomeItems: "Select Journeys",
                                allItemsAreSelected: "All Journeys are Selected",
                                selectAll: "Select All Journeys",
                                search: "Search Journey",
                            }
                        }
                        searchPlaceholder={"Select Modules"}
                        options={journeyOptions}
                        selected={workflow.triggerFilters.journeyIds}
                        onSelectedChanged={onSelectJourney}
                    />
                    <small>Filter by journey. Multiple values work like an OR condition.
                    </small>
                </Loader>
            </div>
        </div>)
    }
}
