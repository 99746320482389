import React from 'react';
import {
    getJourneys,
    getModuleBatches,
    getModules,
    getPrograms,
    getSampleSelfPaceCompletedData,
    getManagerTypeDefinitions,
    getSkills,
    getEvaluations, getEvaluationRuns
} from "../Service/Service";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from 'draft-js';
import Loader from 'react-loader-advanced';
import {confirmAlert} from 'react-confirm-alert'; // Import
import draftToHtml from 'draftjs-to-html';
import {DelayForm} from "./SubComponents/Actions/DelayForm";
import {SelfpacedForm} from "./SubComponents/Actions/SelfpacedForm";
import {JourneyForm} from "./SubComponents/Actions/JourneyForm";
import {EmailForm} from "./SubComponents/Actions/EmailForm";
import {EvaluationForm} from "./SubComponents/Actions/EvaluationForm";
import {WebhookForm} from "./SubComponents/Actions/WebhookForm";


class ActionComponent extends React.Component {
    constructor(props) {
        super(props);
        const blocksFromHTML = convertFromHTML(this.props.currentAction.emailData.body);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        );
        this.state = {
            action: this.props.currentAction,
            loading: true,
            programs: [],
            skills: [],
            modules: [],
            journeys: [],
            evaluations: [],
            evaluationRuns:[],
            evaluators: [],
            sampleJourneys: {},
            sampleClassroomdata: {},
            sampleJourneydata: {},
            moduleBatches: [],
            validEmail: true,
            sampleSelfpacedData: {},
            loadingPrograms: false,
            loadingEvaluationRuns:false,
            loadingSkills: false,
            loadingModules: false,
            loadingBatches: false,
            loadingSelfPacedSample: false,
            loadingEvaluators: false,
            loadingClassroomSample: false,
            loadingJourneySample: false,
            loadingEvaluations: false,
            loadingEvaluator: false,
            editorState: EditorState.createWithContent(state)
        }

    }

    componentDidMount() {
        //  this.updateData();\

        if (this.state.action && this.state.action.selectedProgram) {
            this.getProgramsData();
        }
        else if(this.state.action.assignEvaluation()){
            this.getEvaluations(this.props);
        }
        else if(this.state.action.triggerWebhook()){
            this.getSelfpacedModuleCompletedData();
        }
        else {
            this.setState({
                loading: false
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            action: nextProps.currentAction,
            loadingPrograms: true,
            loadingSkills: true,
            loadingModules: true,
            loadingSelfPacedSample: true,
            loadingJourneys: true
        }, () => {
            if (nextProps.loadData || !nextProps.currentAction.selectedProgram) {
                if (nextProps.currentAction.assignSPModule() || nextProps.currentAction.assignCRModule()) {
                    this.getProgramsData();
                    this.setState({
                        loadingJourneys: false
                    })
                }
                if (nextProps.currentAction.assignJourney()) {
                    this.getJourneyData();
                    this.setState({
                        loadingJourneys: false,
                        loadingSkills: false
                    })
                }
                if(nextProps.currentAction.assignEvaluation()){
                    this.getEvaluations(nextProps);
                }
                if(nextProps.currentAction.triggerWebhook()){
                    this.getSelfpacedModuleCompletedData();
                }
                if (nextProps.currentAction.sendEmail()) {
                    const blocksFromHTML = convertFromHTML(nextProps.currentAction.emailData.body);
                    const state = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap,
                    );
                    this.setState({
                        editorState: EditorState.createWithContent(state),
                        loadingPrograms: false,
                        loadingJourneys: false,
                        loadingSkills: false,
                        loadingModules: false,
                        loadingSelfPacedSample: false
                    })
                }
                else {
                    this.clearLoadingState();
                }
            }
            else {
                this.clearLoadingState();
            }
        })
    }

    clearLoadingState = () => {
        this.setState({
            loadingPrograms: false,
            loadingJourneys: false,
            loadingSkills: false,
            loadingModules: false,
            loadingSelfPacedSample: false
        })
    }
    getProgramsData = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                loadingPrograms: true
            }, function () {
                getPrograms().then(data => {
                    this.setState({
                        programs: data,
                        loadingPrograms: false
                    }, () => {
                        this.getSkillsData();
                        resolve();
                    });
                });
            })
        });
    }
    getSkillsData = () => {
        return new Promise((resolve, reject) => {
            if (this.state.action.selectedProgram) {
                this.setState({
                    loadingSkills: true
                }, function () {
                    getSkills([this.state.action.selectedProgram]).then(data => {
                        this.setState({
                            skills: data,
                            loadingSkills: false
                        }, () => {
                            this.getModulesData();
                            resolve();
                        });
                    });
                })
            }
            else {
                this.setState({
                    skills: [],
                    loadingSkills: false
                }, () => {
                    resolve();
                });
            }
        });
    }
    getModulesData = () => {
        return new Promise((resolve, reject) => {
            if (this.state.action.selectedSkill >= 0) {
                this.setState({
                    loadingModules: true
                }, function () {
                    var moduleType = null;
                    if (this.state.action.assignCRModule()) {
                        moduleType = 4;
                    }
                    else if (this.state.action.assignSPModule()) {
                        moduleType = 1;
                    }
                    getModules([this.state.action.selectedProgram], [this.state.action.selectedSkill], moduleType).then(data => {
                        this.setState({
                            modules: data,
                            loading: false,
                            loadingModules: false
                        }, () => {
                            this.getSelfpacedModuleCompletedData();

                            resolve();
                        });

                    });
                })
            }
            else {
                this.setState({
                    modules: [],
                    loadingModules: false
                }, () => {
                    resolve();
                });
            }
        });
    }
    getEvaluations = (props) => {
        let action = props.currentAction;
        if (props.currentAction.assign360Evaluation()){
            action.evaluatee = "{{userId}}";
            action.userId = "{{userId}}";
        }
        this.setState({
            loadingEvaluations: true,
            loadingEvaluators: true
        }, function () {
            let evaluationType = 2;
            if (props.currentAction.assignStandAloneEvaluation()) {
                evaluationType = 3;
            }
            getEvaluations(evaluationType).then(evaluations => {
                getManagerTypeDefinitions().then((evaluators) => {
                    getEvaluationRuns(action.evaluationId).then((evaluationRuns)=>{
                        getSampleSelfPaceCompletedData().then(sampleSelfPacedData => {
                            this.setState({
                                evaluations: evaluations,
                                evaluators: evaluators,
                                evaluationRuns: evaluationRuns,
                                sampleSelfpacedData:sampleSelfPacedData[0],
                                action: action,
                                loadingEvaluators: false,
                                loadingEvaluations: false,
                                loadingSelfPacedSample: false,
                                loading: false
                            })
                        });
                    })
                })
            })
        });
    }
    getEvaluationRuns=(evaluationId)=>{
        this.setState({
            loadingEvaluationRuns:true
        },()=>{
            getEvaluationRuns(evaluationId).then((runs)=>{
                this.setState({
                    loadingEvaluationRuns:false,
                    evaluationRuns:runs
                })
            })
        })
    }
    getJourneyData = () => {
        this.setState({
            loadingJourneys: true
        }, function () {
            getJourneys().then(journeysData => {
                this.setState({
                    journeys: journeysData,
                    loadingJourneys: false
                }, () => {
                    this.getSelfpacedModuleCompletedData();
                })
            })
        });
    }
    getSelfpacedModuleCompletedData = () => {
        this.setState({
            loadingSelfPacedSample: true
        }, function () {
            getSampleSelfPaceCompletedData().then(selpacedData => {
                this.setState({
                    sampleSelfpacedData: selpacedData[0],
                    loadingSelfPacedSample: false,
                    loading: false
                }, () => {
                    if (this.state.action.assignCRModule()) {
                        this.setState({
                            loadingBatches: true
                        })
                        getModuleBatches(this.state.action.moduleId).then(batches => {
                            this.setState({
                                moduleBatches: batches,
                                loadingBatches: false
                            })

                        });
                    }
                })

            });
        });

    }

    render() {
        let formControl = "";
        if (this.state.loading) {
            return (<Loader show={this.state.loading} message={'fetching...'}>
                <div className={"action-loading"}></div>
            </Loader>)
        }

        if (this.state.action.assignDelay()) {
            formControl = <DelayForm
                action={this.state.action}
                onDelayTimeSelect={this.setDelayTime}
                onDelayMinutesChanged={this.setDelayDropDown}
                onDelayUnitChanged={this.setDelayUnit}
                onDelayChanged={this.setDelay}
            />;
        }
        else if (this.state.action.assignSPModule() || this.state.action.assignCRModule()) {
            formControl = <SelfpacedForm
                action={this.state.action}
                programs={this.state.programs}
                skills={this.state.skills}
                modules={this.state.modules}
                loadingPrograms={this.state.loadingPrograms}
                loadingSkills={this.state.loadingSkills}
                loadingModules={this.state.loadingModules}
                onResetModule={this.setResetModule}
                loadingBatches={this.state.loadingBatches}
                onValidateChange={this.setValidationForTrigger}
                loadingSelfPacedSample={this.state.loadingSelfPacedSample}
                sampleSelfpacedData={this.state.sampleSelfpacedData}
                moduleBatches={this.state.moduleBatches}
                onSelectProgram={this.setselectedProgram}
                onSelectSkill={this.setselectedSkill}
                onSelectModule={this.setModuleId}
                onSelectBatch={this.setBatch}
                onSelectUser={this.setUser}
            />
        }
        else if (this.state.action.assignJourney()) {
            formControl = <JourneyForm
                action={this.state.action}
                journeys={this.state.journeys}
                loadingJourney={this.state.journeys}
                onValidateChange={this.setValidationForTrigger}
                loadingSelfPacedSample={this.state.loadingSelfPacedSample}
                sampleSelfpacedData={this.state.sampleSelfpacedData}
                onSelectJourney={this.setJourneyId}
                onSelectUser={this.setUser}
            />
        }
        else if (this.state.action.sendEmail()) {
            formControl = <EmailForm
                action={this.state.action}
                validEmail={this.state.validEmail}
                editorState={this.state.editorState}
                onAddressChange={this.setToAddress}
                onSubjectChange={this.setSubject}
                onValidateChange={this.setValidationForTrigger}
                onEditorStateChange={this.onEditorStateChange}
            />
        }
        else if (this.state.action.triggerWebhook()) {
            formControl =  <WebhookForm
                action={this.state.action}
                loadingSelfPacedSample={this.state.loadingSelfPacedSample}
                onMethodChange={this.setWebhookMethod}
                onUrlChange={this.setwebhookUrl}
                onSelectParamsData={this.setParamsData}
                onValidateChange={this.setValidationForTrigger}
                sampleSelfpacedData={this.state.sampleSelfpacedData}
            />
        }
        else if (this.state.action.assignEvaluation()) {
            formControl = <EvaluationForm
                action={this.state.action}
                evaluations={this.state.evaluations}
                evaluators={this.state.evaluators}
                loadingEvaluators={this.state.loadingEvaluators}
                loadingEvaluationRuns = {this.state.loadingEvaluationRuns}
                evaluationRuns ={this.state.evaluationRuns}
                sampleSelfpacedData={this.state.sampleSelfpacedData}
                loadingEvaluations={this.state.loadingEvaluations}
                onSelectEvaluation={this.setEvaluation}
                onSelectEvaluationRun={this.setEvaluationRun}
                onSelectEvaluator={this.setEvaluator}
                onValidateChange={this.setValidationForTrigger}
                onSelectEvaluationCategory={this.setEvaluationCategory}
                onSelectUser={this.setUser}
            />
        }

        return (<div className={"action-control form-control"}>
            {formControl}
            <div className={"button"}>
                <button className={"app-button  v1 delete"} onClick={this.deleteAction}>Delete Action</button>
                <button disabled={!this.validAction()} className={"app-button v1"} onClick={this.onCompleteAction}>Done
                </button>
            </div>
        </div>);
    }

    validAction = () => {
        const {action} = this.state;
        if (action.assignSPModule()) {
            return (this.state.action.moduleId > 0 && this.state.action.userId)
        }
        else if (action.assignCRModule()) {
            return (this.state.action.moduleId > 0 && this.state.action.userId && this.state.action.batchId > 0);
        }
        else if (action.assignDelay()) {
            return this.state.action.delayFor > 0 && this.state.action.delayUnit > 0;
        }
        else if (action.assignJourney()) {
            return (this.state.action.journeyId > 0 && this.state.action.userId)
        }
        else if (action.assign360Evaluation()) {
            if(action.evaluationCategory === 1){
                return (this.state.action.evaluationId > 0    && this.state.action.userId)
            }
            return (this.state.action.evaluationId > 0 && this.state.action.evaluators.length > 0  && this.state.action.userId)
        }
        else if (action.assignStandAloneEvaluation()) {
            return (this.state.action.evaluationId > 0 && this.state.action.userId)
        }
        else if (action.triggerWebhook()) {
            return (this.state.action.webhook && this.state.action.webhook.webhookUrl && this.validateUrl(this.state.action.webhook.webhookUrl))
        }
        else if (action.sendEmail()) {
            return (this.state.action.emailData.toAddress && this.validateEmail() && this.state.action.emailData.subject && this.state.action.emailData.body);
        }

    }
    validateUrl = (url )=>{
        // eslint-disable-next-line
        var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return  pattern.test(url);
    }
    validateEmail = () => {
        // let email =this.state.action.emailData.toAddress;
        // var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //
        // return re.test(email);
        return true;
    }

    setToAddress = (event) => {
        let currentAction = this.state.action;
        currentAction.emailData.toAddress = event.currentTarget.value;
        this.setState({
            action: currentAction
        })
    }
    setResetModule=(value)=>{
        let currentAction = this.state.action;
        currentAction.resetModuleProgress = value;
        this.setState({
            action: currentAction
        })
    }
    setValidationForTrigger=(value)=>{
        let currentAction = this.state.action;
        currentAction.validateEventCriteria = value;
        this.setState({
            action: currentAction
        })
    }
    setSubject = (event) => {
        let currentAction = this.state.action;
        currentAction.emailData.subject = event.currentTarget.value;
        this.setState({
            action: currentAction
        })
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState: editorState
        }, () => {
            let currentAction = this.state.action;
            currentAction.emailData.body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            this.setState({
                action: currentAction
            })
        })


    }
    setBody = (event) => {
        let currentAction = this.state.action;
        currentAction.emailData.body = event.currentTarget.value;
        this.setState({
            action: currentAction
        })
    }
    setEvaluator = (value) => {
        let currentAction = this.state.action;
        currentAction.evaluators =value;
        this.setState({
            action: currentAction
        })
    }
    setParamsData=(value, a,b)=>{
        let currentAction = this.state.action;
        currentAction.webhook.paramsDataKey =value;
        this.setState({
            action: currentAction
        })
    }
    setwebhookUrl=(e)=>{
        let currentAction = this.state.action;
        currentAction.webhook.webhookUrl = e.target.value;
        this.setState({
            action: currentAction
        })
    }
    setWebhookMethod=(method)=>{
        let currentAction = this.state.action;
        currentAction.webhook.method = method.value;
        this.setState({
            action: currentAction
        })
    }
    setEvaluationRun=(evaluationRun)=>{
        let currentAction = this.state.action;
        currentAction.evaluationRunId = evaluationRun.value;
        this.setState({
            action: currentAction
        })
    }
    setEvaluation = (evaluation) => {
        let currentAction = this.state.action;
        currentAction.evaluationId = evaluation.value;
        this.setState({
            action: currentAction
        },()=>{
            this.getEvaluationRuns(currentAction.evaluationId);
        })
    }
    setEvaluationCategory=(evaluation)=>{
        let currentAction = this.state.action;
        currentAction.evaluationCategory = evaluation.value;
        this.setState({
            action: currentAction
        })
    }
    setUser = (selectUser) => {
        let currentAction = this.state.action;
        currentAction.userId = selectUser.value;
        if(currentAction.assignEvaluation()){
            currentAction.evaluatee = selectUser.value;
        }
        this.setState({
            action: currentAction
        })
    }
    setBatch = (selectedBatch) => {
        let currentAction = this.state.action;
        currentAction.batchId = selectedBatch.value;
        this.setState({
            action: currentAction
        })
    }
    setDelayTime = (selectedValue)=>{
        let currentAction = this.state.action;
        currentAction.delayExecuteAt = JSON.stringify(selectedValue);
        this.setState({
            action:currentAction
        })
    }
    setDelayDropDown = (selectedValue) => {
        let currentAction = this.state.action;
        currentAction.delayFor = Number(selectedValue.value);
        this.setState({
            action: currentAction
        })
    }
    setDelay = (event) => {
        let currentAction = this.state.action;
        currentAction.delayFor = Number(event.currentTarget.value);
        this.setState({
            action: currentAction
        })
    }
    setJourneyId = (seletedJourney) => {
        let currentAction = this.state.action;
        currentAction.journeyId = seletedJourney.value
        this.setState({
            action: currentAction
        }, () => {
            this.getSelfpacedModuleCompletedData();
        });
    }
    setDelayUnit = (currentUnit) => {
        let currentAction = this.state.action;
        currentAction.delayUnit = currentUnit.value;
        this.setState({
            action: currentAction
        })
    }
    setselectedProgram = (selectedProgram) => {
        let currentAction = this.state.action;
        currentAction.selectedProgram = selectedProgram.value
        this.setState({
            action: currentAction
        }, () => {
            this.getSkillsData()
        })
    }
    setselectedSkill = (selectedSkill) => {
        let currentAction = this.state.action;
        currentAction.selectedSkill = selectedSkill.value
        this.setState({
            action: currentAction
        }, () => {
            this.getModulesData()
        })
    }
    setModuleId = (selectedModule) => {
        let currentAction = this.state.action;
        currentAction.moduleId = selectedModule.value
        this.setState({
            action: currentAction
        }, () => {
            if (this.state.action.assignCRModule()) {
                this.getSelfpacedModuleCompletedData();
                this.setState({
                    loadingBatches: true
                })
                getModuleBatches(this.state.action.moduleId).then(batches => {
                    this.setState({
                        moduleBatches: batches,
                        loadingBatches: false
                    })
                });
            }
            this.getSelfpacedModuleCompletedData();

        })
    }
    onCompleteAction = () => {
        this.props.onDone(this.props.currentActionIndex);
    }
    deleteAction = () => {
        confirmAlert({
            title: 'Alert',
            message: 'Are you want to delete this action?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.onDelete(this.props.currentActionIndex)
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    }
}

export default ActionComponent;
