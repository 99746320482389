let workflowServiceUrl = "https://workflow-msvc.disprz.com/";
let _dataCache = {};
export async function getWorkflows() {
    let url = workflowServiceUrl + "api/workflowui/getAllWorkflows";
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            return data
        });
}


export async function getPrograms() {
    let url = workflowServiceUrl + "api/workflowui/getPrograms";
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}
export async function getUdfFields() {
    let url = workflowServiceUrl + "api/workflowui/getUdfFields";
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}
export async function getUdfFieldValue(fieldId) {
    let url = `${workflowServiceUrl}api/workflowui/getUdfValuesForFieldId?fieldId=${fieldId}`;
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}


export async function getSkills(programIds) {
    if(!programIds ){
        return [];
    }
    let url = `${workflowServiceUrl}api/workflowui/getSkillsForPrograms?programIds=${programIds.join(",")}`;
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}
export async function getModules(programIds, skillIds, moduleType) {
    if(!programIds || !skillIds){
        return [];
    }
    if(!moduleType){
        moduleType = null;
    }
    let url = `${workflowServiceUrl}api/workflowui/getModulesForSkills?programIds=${programIds.join(",")}&skillIds=${skillIds.join(",")}&moduleType=${moduleType}`;
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}

export async function getJourneys() {
    let url = workflowServiceUrl + "api/workflowui/getJourneys";
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}

export async function getSampleSelfPaceCompletedData() {
    let url = workflowServiceUrl + "api/workflowui/getSampleSelfPaceCompletedData";
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}
export async function getSampleJourneyCompletionData() {
    let url = workflowServiceUrl + "api/workflowui/getSampleSelfPaceCompletedData";
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}
export async function getSampleClassRoomCompletedData() {
    let url = workflowServiceUrl + "api/workflowui/getSampleSelfPaceCompletedData";
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}

export async function getModuleBatches(moduleId) {
    let url = workflowServiceUrl + "api/workflowui/getModuleBatches?moduleId=" + moduleId;
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}
export async function getEvaluations(evaluationType) {
    let url = workflowServiceUrl + "api/workflowui/getEvaluations?evaluationType=" + evaluationType;
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}
export async function getEvaluationRuns(evaluationId) {
    if(!evaluationId){
        return [];
    }
    let url = workflowServiceUrl + "api/workflowui/getEvaluationRuns?evaluationId=" + evaluationId;
    if(_dataCache[url]){
        return _dataCache[url];
    }
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}
export async function getManagerTypeDefinitions() {
    let url = workflowServiceUrl + "api/workflowui/getManagerTypeDefinitions";
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}
export async function saveWorkflow(workflow) {
    let url = workflowServiceUrl + "api/workflowui/saveWorkflow/";
    let request = {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        },
        body:JSON.stringify(workflow)
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}

export async function getAllMetricsMaster(apiURL){
    const INITIAL_FETCH_COUNT = 25;
    let url =`${apiURL}v1/api/metrics?fetchCount=${INITIAL_FETCH_COUNT}`;
    let request = {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    const metrics =  await fetch(url, request).then(response => response.json()).then(res=>res);

    if(metrics.totalCount > INITIAL_FETCH_COUNT){
        const url = `${apiURL}v1/api/metrics?fetchCount=${metrics.totalCount}`;
        return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
    }
    else{
        _dataCache[url] = metrics;
        return metrics;
    }
}

export async function deleteWorkflow(id) {
    let url = `${workflowServiceUrl}api/workflowui/deleteWorkflow/${id}`;
    let request = {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Token": sessionStorage.getItem("accessToken")
        }
    }
    return await fetch(url, request).then(response => response.json())
        .then(data => {
            _dataCache[url] = data;
            return data
        });
}
