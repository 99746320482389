export const getTriggers = () => {
    return [
        {
            triggerName: "User Created",
            triggerValue: "userCreated",
            helpText: "Triggers when a new user is created."
        },
        {
            triggerName: "User Attribute Change",
            triggerValue: "userAttributeChange",
            helpText: "Triggers when a user attribute value change."
        },
        {
            triggerName: "Self-paced module completed",
            triggerValue: "selfPacedModuleCompleted",
            helpText: "Triggers when a Self-paced module is completed by a learner."
        },
        {
            triggerName: "Classroom module completed",
            triggerValue: "classroomModuleCompleted",
            helpText: "Triggers when a Classroom module is completed by a learner."
        },
        {
            triggerName: "Journey Completed",
            triggerValue: "userJourneyCompleted",
            helpText: "Triggers when a Journey is completed by a learner."
        },
        {
            triggerName: "KPI Metric value added",
            triggerValue: "kpiMetricValueAdded",
            helpText: "Triggers when a kpi value added."
        },
        {
            triggerName: "Standalone Survey Completed",
            triggerValue: "standaloneSurveySubmitted",
            helpText: "Triggers when a Standalone survey is completed."
        },
        {
            triggerName: "360-degree feedback Completed",
            triggerValue: "360EvaluationSubmitted",
            helpText: "Triggers when a 360 degree feedback is completed."
        },
        {
            triggerName: "User Tenure Completed",
            triggerValue: "userTenureCompleted",
            helpText: "Triggers when a date attribute on a user account meets a certain duration."
        }
    ];
}

export const getActions = () => {
    return [

        {
            actionName: "Assign Self-paced Module",
            actionValue: "assignSelfpacedModule",
            helpText: "Assigns a particular Self-paced module to a User."
        },
        {
            actionName: "Assign Classroom Module",
            actionValue: "assignClassroomModule",
            helpText: "Assigns a particular Classroom module to a User."
        },
        {
            actionName: "Assign Journey",
            actionValue: "assignJourney",
            helpText: "Assigns a particular journey module to a User."
        },
        {
            actionName: "Assign Evaluation Survey",
            actionValue: "assignEvaluationSurvey",
            helpText: "Assigns a feedback to evaluator for an evaluatee."
        }
        ,
        {
            actionName: "Assign Standalone Survey",
            actionValue: "assignStandaloneSurvey",
            helpText: "Assigns a survey to a User."
        },
        {
            actionName: "Send Email",
            actionValue: "sendmail",
            helpText: "Create and send a new email message."
        },
        {
            actionName: "Trigger Webhook",
            actionValue: "webhookTrigger",
            helpText: "Trigger a webhook with get/post method."
        },
        {
            actionName: "Delay",
            actionValue: "delay",
            helpText: "Wait for set amount of times before executing your actions."
        }
    ]
}
