import React from 'react';
import {
    getEvaluations,
    getJourneys,
    getModules,
    getPrograms,
    getSkills,
    getUdfFields,
    getUdfFieldValue
} from "../Service/Service";
import SelectSearch from 'react-select-search';
import MultiSelect from "@khanacademy/react-multi-select";
import Loader from 'react-loader-advanced';
import {ProgramFilters} from "./SubComponents/Triggers/ProgramFilters";
import {SkillsFilter} from "./SubComponents/Triggers/SkillsFilter";
import {ModuleFilters} from "./SubComponents/Triggers/ModuleFilters";
import {JourneyFilters} from "./SubComponents/Triggers/JourneyFilters";
import {SurveyFilters} from "./SubComponents/Triggers/SurveyFilters";
import ComparisionFilters from "./SubComponents/Triggers/ComparisionFilters";
import TenureFilters from "./SubComponents/Triggers/TenureFilters";


class Trigger extends React.Component {
    constructor(props) {
        super(props);
        const disableSave = this.props.currentWorkflow.isUserCompletesTenureTrigger() || this.props.currentWorkflow.isKPIValueAddTrigger()
        this.state = {
            currentWorkflow: this.props.currentWorkflow,
            trigger: "",
            isLoading: true,
            loadingModules: false,
            loadingSkills: false,
            loadingPrograms: false,
            loadingJourneys: false,
            loadingEvaluations: false,
            loadingUA1: true,
            loadingUAV1: false,
            //loadingUA2:false,
            loadingUAV2: false,
            // loadingUA3:false,
            loadingUAV3: false,
            programIds: [],
            skillIds: [],
            moduleIds: [],
            journeys: [],
            journeyIds: [],
            evaluationIds: [],
            programs: [],
            skills: [],
            modules: [],
            evaluations: [],
            udfFieldOptions: [],
            userAttVal1: [],
            userAttVal2: [],
            userAttVal3: [],
            isSaveDisabled: props.currentWorkflow.isUserCompletesTenureTrigger()
        }
        this.comparisonFilter = null;
    }

    componentWillReceiveProps(nextProps) {
        const disableSave = nextProps.currentWorkflow.isUserCompletesTenureTrigger() || this.props.currentWorkflow.isKPIValueAddTrigger()
        this.setState({
            currentWorkflow: nextProps.currentWorkflow,
            isSaveDisabled: disableSave
        }, () => {
            if (nextProps.loadData) {
                if (!nextProps.currentWorkflow.isUserCreatedTrigger() && !nextProps.currentWorkflow.isJourneyTrigger() && !nextProps.currentWorkflow.isStandaloneSurveyCompletedTrigger() && !this.props.currentWorkflow.is360EvaluationCompletedTrigger()) {
                    this.getProgramsData();
                } else if (nextProps.currentWorkflow.isJourneyTrigger()) {
                    this.getJourneyData();
                } else if (nextProps.currentWorkflow.isStandaloneSurveyCompletedTrigger() || this.props.currentWorkflow.is360EvaluationCompletedTrigger()) {
                    this.getEvaluationsData();
                }
            }
        });
    }

    componentDidMount() {
        this.getUDFFields();
        if (this.state.currentWorkflow.triggerFilters.fieldId1) {
            this.getUserAttributeFieldValue1();
        }
        if (this.state.currentWorkflow.triggerFilters.fieldId2) {
            this.getUserAttributeFieldValue2();
        }
        if (this.state.currentWorkflow.triggerFilters.fieldId3) {
            this.getUserAttributeFieldValue3();
        }

        if (!this.props.currentWorkflow.isUserCreatedTrigger() && !this.props.currentWorkflow.isJourneyTrigger() && !this.props.currentWorkflow.isStandaloneSurveyCompletedTrigger() && !this.props.currentWorkflow.is360EvaluationCompletedTrigger()) {
            this.getProgramsData();
        } else if (this.props.currentWorkflow.isJourneyTrigger()) {
            this.getJourneyData();
        } else if (this.props.currentWorkflow.isStandaloneSurveyCompletedTrigger() || this.props.currentWorkflow.is360EvaluationCompletedTrigger()) {
            this.getEvaluationsData();
        }
    }

    getUDFFields = () => {
        this.setState({
            loadingUA1: true
        }, function () {
            getUdfFields().then(data => {
                const udfFieldOptions = data.map((udfField) => {
                    return {
                        name: udfField.fieldName,
                        value: udfField.id
                    }
                });
                this.setState({
                    udfFieldOptions,
                    loadingUA1: false
                });
            });
        })
    }
    getUserAttributeFieldValue1 = () => {
        this.setState({
            loadingUAV1: true
        }, function () {
            getUdfFieldValue(this.state.currentWorkflow.triggerFilters.fieldId1).then(data => {
                this.setState({
                    userAttVal1: data,
                    loadingUAV1: false
                });
            });
        })
    }
    getUserAttributeFieldValue2 = () => {
        this.setState({
            loadingUAV2: true
        }, function () {
            getUdfFieldValue(this.state.currentWorkflow.triggerFilters.fieldId2).then(data => {
                this.setState({
                    userAttVal2: data,
                    loadingUAV2: false
                });
            });
        })
    }
    getUserAttributeFieldValue3 = () => {
        this.setState({
            loadingUAV3: true
        }, function () {
            getUdfFieldValue(this.state.currentWorkflow.triggerFilters.fieldId3).then(data => {
                this.setState({
                    userAttVal3: data,
                    loadingUAV3: false
                });
            });
        })
    }
    getProgramsData = () => {
        this.setState({
            loadingPrograms: true,
            loadingSkills: true,
            loadingModules: true
        }, function () {
            getPrograms().then(data => {
                this.setState({
                    programs: data,
                    loadingPrograms: false
                }, () => {
                    this.getSkillsData();
                });
            });
        })
    }
    getSkillsData = () => {
        if (this.state.currentWorkflow.triggerFilters.programIds.length) {
            this.setState({
                loadingSkills: true
            }, function () {
                getSkills(this.state.currentWorkflow.triggerFilters.programIds).then(data => {
                    this.setState({
                        skills: data,
                        loadingSkills: false
                    }, () => {
                        this.getModulesData();
                    });
                });
            })
        } else {
            this.setState({
                skills: [],
                loadingSkills: false,
                loadingModules: false
            });
        }

    }
    getModulesData = () => {
        if (this.state.currentWorkflow.triggerFilters.skillIds.length) {
            this.setState({
                loadingModules: true
            }, function () {
                var moduleType = null;
                if (this.state.currentWorkflow.isSPModuleCompletedTrigger()) {
                    moduleType = 1;
                } else if (this.state.currentWorkflow.isCRModuleCompletedTrigger()) {
                    moduleType = 4;
                }
                getModules(this.state.currentWorkflow.triggerFilters.programIds, this.state.currentWorkflow.triggerFilters.skillIds, moduleType).then(data => {
                    this.setState({
                        modules: data,
                        loadingModules: false
                    });
                });
            })
        } else {
            this.setState({
                modules: [],
                loadingModules: false
            });
        }
    }
    getJourneyData = () => {
        this.setState({
            loadingJourneys: true
        }, function () {
            getJourneys().then(journeysData => {
                this.setState({
                    journeys: journeysData,
                    loadingJourneys: false
                })
            })
        });
    }
    getEvaluationsData = () => {
        this.setState({
            loadingEvaluations: true
        }, function () {
            var evaluationType = null;
            if (this.state.currentWorkflow.is360EvaluationCompletedTrigger()) {
                evaluationType = 2;
            } else if (this.state.currentWorkflow.isStandaloneSurveyCompletedTrigger()) {
                evaluationType = 3;
            }
            getEvaluations(evaluationType).then(evaluationsData => {
                this.setState({
                    evaluations: evaluationsData,
                    loadingEvaluations: false
                })
            })
        });
    }


    render() {
        if (this.state.loadingUA1) {
            return (<Loader show={this.state.loadingUA1} message={'fetching...'}>
                <div className={"action-loading"}/>
            </Loader>)
        }
        const {currentWorkflow, isSaveDisabled} = this.state;
        const DisableSave = isSaveDisabled ||  ( currentWorkflow.isKPIValueAddTrigger() && this.comparisonFilter?.state.metrics.length === 0);
        return (
            <div className="form-control">
                <SurveyFilters
                    workflow={currentWorkflow}
                    evaluations={this.state.evaluations}
                    loadingEvaluations={this.state.loadingEvaluations}
                    onEvaluationSelect={this.setSelectedEvaluations}
                />
                <ProgramFilters
                    workflow={currentWorkflow}
                    programs={this.state.programs}
                    loadingPrograms={this.state.loadingPrograms}
                    onProgramSelect={this.setSelectedPrograms}
                />
                <SkillsFilter
                    workflow={currentWorkflow}
                    skills={this.state.skills}
                    loadingSkills={this.state.loadingSkills}
                    onSkillSelect={this.setSelectedSkills}
                />
                <ModuleFilters
                    workflow={currentWorkflow}
                    modules={this.state.modules}
                    loadingModules={this.state.loadingModules}
                    onModuleSelect={this.setSelectedModules}
                />
                <JourneyFilters
                    workflow={currentWorkflow}
                    journeys={this.state.journeys}
                    loadingJourneys={this.state.loadingJourneys}
                    onSelectJourney={this.setSelectedJourney}
                />
                <TenureFilters
                    onChangeTenureDateField={this.onChangeTenureDateField}
                    udfFieldOptions={this.state.udfFieldOptions}
                    workflow={currentWorkflow}
                    onChangeTenureDateFormat={this.onChangeTenureDateFormat}
                    onChangeTenureValue={this.onChangeTenureValue}
                />
                <ComparisionFilters
                    enableMultipleConditions={currentWorkflow.isKPIValueAddTriggerLegacy() ? true : false}
                    ref={(ref) => {
                        this.comparisonFilter = ref
                    }}
                    workflow={currentWorkflow}
                    onInitKpiValue = {this.setButtonStatus}                    
                />
                {this.getUserAtrribute1Field()}
                {this.getUserAtrribute1Values()}
                {this.getUserAtrribute2Field()}
                {this.getUserAtrribute2Values()}
                {this.getUserAtrribute3Field()}
                {this.getUserAtrribute3Values()}
                <div className={"button"}>
                    <button
                        className={`app-button v1 ${DisableSave ? 'disabled' : ''}`}
                        onClick={() => !DisableSave && this.onContinueTrigger()}
                        disabled={DisableSave}>
                        {"Continue"}
                    </button>
                </div>
            </div>)
    }

    getUserAtrribute1Field = () => {
        return (<div className={"form-field"}>
            <div className="label"> User Attribute 1 : <small>(Optional)</small></div>
            <div className="component">
                <Loader show={this.state.loadingUA1} message={'fetching...'}>
                    <SelectSearch onChange={this.setUserAttribute1Field} options={this.state.udfFieldOptions}
                                  value={this.state.currentWorkflow.triggerFilters.fieldId1}
                                  placeholder="Select User Attribute"/>
                    <small>The first (out of a maximum of 3) user attribute / user defined fields to filter by (for
                        instance, Business Unit). If there is more than one user attribute defined, the trigger works
                        only if ALL user attribute conditions are met.
                    </small>
                </Loader>
            </div>
        </div>)
    }
    getUserAtrribute1Values = () => {
        let udfValueOptions = [];
        this.state.userAttVal1.forEach((userAtt) => {
            if (this.state.currentWorkflow.triggerFilters.fieldValue1.includes(userAtt.value)) {
                udfValueOptions.splice(0, 0, {
                    label: userAtt.value,
                    value: userAtt.value
                })
            } else {
                udfValueOptions.push({
                    label: userAtt.value,
                    value: userAtt.value
                })
            }

        }, this);
        let closeBtn = null;
        if (this.state.currentWorkflow.triggerFilters.fieldValue1.length) {
            closeBtn = (<span className={"clear-icon"} onClick={this.setUserAttribute1Value.bind(null, [])}><i
                className="fa fa-times"/></span>);
        }
        return (<div className={"form-field"}>
            <div className="label"> User Attribute 1 Value(s) : <small>(Optional)</small></div>
            <div className="component" disabled={!this.state.currentWorkflow.triggerFilters.fieldId1}>
                <Loader show={this.state.loadingUAV1} message={'fetching...'}>
                    <div className={"select-holder"}>
                        <MultiSelect
                            overrideStrings={
                                {
                                    selectSomeItems: "Select User Attribute Value",
                                    allItemsAreSelected: "All Values are Selected",
                                    selectAll: "Select All Values",
                                    search: "Search Value",
                                }
                            }
                            searchPlaceholder={"Select User Attribute Value"}
                            options={udfValueOptions}
                            selected={this.state.currentWorkflow.triggerFilters.fieldValue1}
                            onSelectedChanged={this.setUserAttribute1Value}
                        />
                        {closeBtn}
                    </div>
                    <small>The possible values of the above selected User Attribute 1. More than one value can be
                        selected here and this trigger works if the user attribute 1 has any of these values.
                    </small>
                </Loader>
            </div>
        </div>)
    }
    getUserAtrribute2Field = () => {
        return (<div className={"form-field"}>
            <div className="label"> User Attribute 2 : <small>(Optional)</small></div>
            <div className="component" disabled={!this.state.currentWorkflow.triggerFilters.fieldValue1.length}>
                <Loader show={this.state.loadingJourneys} message={'fetching...'}>
                    <SelectSearch onChange={this.setUserAttribute2Field} options={this.state.udfFieldOptions}
                                  value={this.state.currentWorkflow.triggerFilters.fieldId2}
                                  placeholder="Select User Attribute"/>
                    <small>The second (out of a maximum of 3) user attribute / user defined fields to filter by (for
                        instance, Department). If there is more than one user attribute defined, the trigger works only
                        if ALL user attribute conditions are met.
                    </small>
                </Loader>
            </div>
        </div>)
    }
    getUserAtrribute2Values = () => {
        let udfValueOptions = [];
        this.state.userAttVal2.forEach((userAtt) => {
            if (this.state.currentWorkflow.triggerFilters.fieldValue2.includes(userAtt.value)) {
                udfValueOptions.splice(0, 0, {
                    label: userAtt.value,
                    value: userAtt.value
                })
            } else {
                udfValueOptions.push({
                    label: userAtt.value,
                    value: userAtt.value
                })
            }
        }, this);
        let closeBtn = null;
        if (this.state.currentWorkflow.triggerFilters.fieldValue2.length) {
            closeBtn = (<span className={"clear-icon"} onClick={this.setUserAttribute2Value.bind(null, [])}><i
                className="fa fa-times"/></span>);
        }
        return (<div className={"form-field"}>
            <div className="label"> User Attribute 2 Value(s): <small>(Optional)</small></div>
            <div className="component" disabled={!this.state.currentWorkflow.triggerFilters.fieldId2}>
                <Loader show={this.state.loadingUAV2} message={'fetching...'}>
                    <div className={"select-holder"}>
                        <MultiSelect
                            overrideStrings={
                                {
                                    selectSomeItems: "Select User Attribute Value",
                                    allItemsAreSelected: "All Values are Selected",
                                    selectAll: "Select All Values",
                                    search: "Search Value",
                                }
                            }
                            disabled={!this.state.currentWorkflow.triggerFilters.fieldId2}
                            searchPlaceholder={"Select User Attribute Value"}
                            options={udfValueOptions}
                            selected={this.state.currentWorkflow.triggerFilters.fieldValue2}
                            onSelectedChanged={this.setUserAttribute2Value}/>
                        {closeBtn}
                    </div>
                    <small>The possible values of the above selected User Attribute 2. More than one value can be
                        selected here and this trigger works if the user attribute 2 has any of these values.
                    </small>
                </Loader>
            </div>
        </div>)
    }
    getUserAtrribute3Field = () => {
        return (<div className={"form-field"}>
            <div className="label"> User Attribute 3 : <small>(Optional)</small></div>
            <div className="component" disabled={!this.state.currentWorkflow.triggerFilters.fieldValue2.length}>
                <Loader show={this.state.loadingJourneys} message={'fetching...'}>
                    <SelectSearch onChange={this.setUserAttribute3Field} options={this.state.udfFieldOptions}
                                  value={this.state.currentWorkflow.triggerFilters.fieldId3}
                                  placeholder="Select User Attribute"/>
                    <small>The third (out of a maximum of 3) user attribute / user defined fields to filter by (for
                        instance, Location). If there is more than one user attribute defined, the trigger works only if
                        ALL user attribute conditions are met.
                    </small>
                </Loader>
            </div>
        </div>)
    }
    getUserAtrribute3Values = () => {
        let udfValueOptions = [];
        this.state.userAttVal3.forEach((userAtt) => {
            if (this.state.currentWorkflow.triggerFilters.fieldValue3.includes(userAtt.value)) {
                udfValueOptions.splice(0, 0, {
                    label: userAtt.value,
                    value: userAtt.value
                })
            } else {
                udfValueOptions.push({
                    label: userAtt.value,
                    value: userAtt.value
                })
            }
        }, this);
        let closeBtn = null;
        if (this.state.currentWorkflow.triggerFilters.fieldValue2.length) {
            closeBtn = (<span className={"clear-icon"} onClick={this.setUserAttribute3Value.bind(null, [])}><i
                className="fa fa-times"/></span>);
        }
        return (<div className={"form-field"}>
            <div className="label"> User Attribute 3 Value(s): <small>(Optional)</small></div>
            <div className="component" disabled={!this.state.currentWorkflow.triggerFilters.fieldId3}>
                <Loader show={this.state.loadingUAV3} message={'fetching...'}>
                    <div className={"select-holder"}>
                        <MultiSelect
                            overrideStrings={
                                {
                                    selectSomeItems: "Select User Attribute Value",
                                    allItemsAreSelected: "All Values are Selected",
                                    selectAll: "Select All Values",
                                    search: "Search Value",
                                }
                            }
                            disabled={!this.state.currentWorkflow.triggerFilters.fieldId3}
                            searchPlaceholder={"Select User Attribute Value"}
                            options={udfValueOptions}
                            selected={this.state.currentWorkflow.triggerFilters.fieldValue3}
                            onSelectedChanged={this.setUserAttribute3Value}/>
                        {closeBtn}
                    </div>
                    <small>The possible values of the above selected User Attribute 3. More than one value can be
                        selected here and this trigger works if the user attribute 3 has any of these values.
                    </small>
                </Loader>
            </div>
        </div>)
    }

    setUserAttribute1Field = (selectedField) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.fieldId1 = selectedField.value;
        this.setState({
            currentWorkflow
        }, () => {
            this.getUserAttributeFieldValue1();
        });
    }
    setUserAttribute2Field = (selectedField) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.fieldId2 = selectedField.value;
        this.setState({
            currentWorkflow
        }, () => {
            this.getUserAttributeFieldValue2();
        });
    }
    setUserAttribute3Field = (selectedField) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.fieldId3 = selectedField.value;
        this.setState({
            currentWorkflow
        }, () => {
            this.getUserAttributeFieldValue3();
        });
    }
    setUserAttribute1Value = (selectedValues) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.fieldValue1 = selectedValues
        this.setState({
            currentWorkflow
        });
    }
    setUserAttribute2Value = (selectedValues) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.fieldValue2 = selectedValues;
        this.setState({
            currentWorkflow
        });
    }
    setUserAttribute3Value = (selectedValues) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.fieldValue3 = selectedValues;
        this.setState({
            currentWorkflow
        });
    }
    setSelectedPrograms = (value) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.programIds = value;
        this.setState({
            currentWorkflow
        }, function () {
            this.getSkillsData();
        })
    }
    setSelectedSkills = (value) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.skillIds = value;
        this.setState({
            currentWorkflow
        }, function () {
            this.getModulesData();
        })
    }
    setSelectedModules = (value) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.moduleIds = value;
        this.setState({
            currentWorkflow
        })
    }

    setSelectedEvaluations = (value) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.evaluationIds = value;
        this.setState({
            currentWorkflow
        })
    }

    onContinueTrigger = () => {
        if (this.state.currentWorkflow.isKPIValueAddTrigger()) {
            let currentWorkflow = this.state.currentWorkflow;
            currentWorkflow.triggerFilters.metricTriggers = this.comparisonFilter.getMetricsArray();
            this.setState({
                currentWorkflow
            }, () => {
                this.props.onContinue();
            })
        } else {
            this.props.onContinue();
        }
    }
    setSelectedJourney = (value) => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.journeyIds = value;
        this.setState({
            currentWorkflow
        })
    }

    onChangeTenureDateField = selectedField => {
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.tenureDateField = selectedField.value;
        const isSaveDisabled = this.checkIsTriggerFieldValueAvailable(currentWorkflow);
        this.setState({
            currentWorkflow,
            isSaveDisabled
        });
    }

    onChangeTenureDateFormat = selectedField => {
        let value=selectedField.target.value
        if (!(/^([^0-9]*)$/.test(value))) {
            return;
         }
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.triggerFilters.tenureDateFormat =value;
        const isSaveDisabled = this.checkIsTriggerFieldValueAvailable(currentWorkflow);
        this.setState({
            currentWorkflow,
            isSaveDisabled
        });
    }

    onChangeTenureValue = event => {
        let currentWorkflow = this.state.currentWorkflow;
        if (event.currentTarget.value.length <= event.currentTarget.maxLength && event.currentTarget.value >= 0) {
            currentWorkflow.triggerFilters.tenureValue = event.currentTarget.value;
            const isSaveDisabled = this.checkIsTriggerFieldValueAvailable(currentWorkflow);
            this.setState({
                currentWorkflow,
                isSaveDisabled
            });
        }
    }

    checkIsTriggerFieldValueAvailable = (currentWorkflow) => {
        const {tenureDateField = '', tenureDateFormat = '', tenureValue = ''} = currentWorkflow.triggerFilters;
        return !!!tenureDateField || !!!tenureDateFormat || !!!tenureValue
    }
    
    setButtonStatus = (disableSave) =>{
        this.setState({
            isSaveDisabled: disableSave
        })
    }
}

export default Trigger
