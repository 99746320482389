import React from 'react';
import './styles/App.scss';
import './styles/selectSearch.scss';
import {ReactComponent as AppIcons} from "./styles/AppIcons.svg";
import IntegrationView from './Views/integrationview' 
function App() {
  return (
    <div className="App">
        <AppIcons/>
      <IntegrationView/>
    </div>
  );
}

export default App;
