import React from 'react';
import SelectSearch from 'react-select-search';
import Loader from 'react-loader-advanced';

export const AssignTo = ({action, loadingSelfPacedSample,moduleName, assigneeLabel, sampleSelfpacedData, onSelectUser}) => {
    let userOptions = [];
    if (action.assignSPModule() || action.assignStandAloneEvaluation()) {
        if (Object.keys(sampleSelfpacedData).length) {
            for (let key in  sampleSelfpacedData) {
                userOptions.push({
                    name: key.replace(/_/g, " ").replace("UserId", "").replace("id", ""),
                    value: "{{" + key + "}}"
                });
            }
        }
        else {
            userOptions.push({
                name: "Select From Trigger",
                value: ""
            });
        }
    }
    else {
        if (Object.keys(sampleSelfpacedData).length) {
            for (let key in  sampleSelfpacedData) {
                userOptions.push({
                    name: key.replace(/_/g, " ").replace("UserId", "").replace("id", ""),
                    value: "{{" + key + "}}"
                });
            }
        }
        else {
            userOptions.push({
                name: "Select From Trigger",
                value: ""
            });
        }
    }
    let disabled = !action.moduleId && !action.journeyId && !action.evaluators.length;
    if(action.assignStandAloneEvaluation()){
        disabled =false;
    }
    return (<div className={"form-field"}>
        <div className={"label"}>{assigneeLabel || "Assign To :"}</div>
        <div className={"component"} disabled={disabled}>
            <Loader show={loadingSelfPacedSample} message={'fetching...'}>
                <SelectSearch key={new Date().valueOf()} onChange={onSelectUser} options={userOptions}
                              value={action.userId||""}
                              placeholder="Select user from trigger"/>
                <small>User to whom the {moduleName} is to be assigned.
                </small>
            </Loader>
        </div>
    </div>)
}