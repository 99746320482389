import React from 'react';
import {AssignTo} from "./Filters/AssignTo";
import {ProgramActionFilter} from "./Filters/ProgramActionFilter";
import {SkillActionFilter} from "./Filters/SkillActionFilter";
import {ModuleActionFilter} from "./Filters/ModuleActionFilter";
import {BatchActionFilter} from "./Filters/BatchActionFilter";
import BooleanComponent from "./Filters/BooleanComponent";


export const SelfpacedForm = ({action,
                                programs,
                                skills,
                                modules,
                                loadingPrograms,
                                loadingSkills,
                                loadingModules,
                                loadingSelfPacedSample,
                                loadingBatches,
                                sampleSelfpacedData,
                                  onValidateChange,
                                  onResetModule,
                                moduleBatches,
                                onSelectProgram,
                                onSelectSkill,
                                onSelectModule,
                                onSelectBatch,
                                onSelectUser}) => {

    let moduleName = "Self-paced module";
    if (action.assignSPModule()) {
        moduleName = "Self-paced module";
    }
    if (action.assignCRModule()) {
        moduleName = "Classroom module";
    }

    let formControl = (<div>
         <ProgramActionFilter
             action={action}
             moduleName={moduleName}
             programs={programs}
             loadingPrograms={loadingPrograms}
             onSelectProgram={onSelectProgram}
         />
        <SkillActionFilter
            action={action}
            skills={skills}
            moduleName={moduleName}
            loadingSkills={loadingSkills}
            onSelectSkill={onSelectSkill}
        />
        <ModuleActionFilter
            action={action}
            moduleName={moduleName}
            modules={modules}
            loadingModules={loadingModules}
            onSelectModule={onSelectModule}
        />
        {action.assignCRModule() && <BatchActionFilter
             action={action}
             moduleName={moduleName}
             moduleBatches={moduleBatches}
             loadingBatches={loadingBatches}
             onSelectBatch={onSelectBatch}
         />}
         <AssignTo
             action={action}
             moduleName={moduleName}
             loadingSelfPacedSample={loadingSelfPacedSample}
             sampleSelfpacedData={sampleSelfpacedData}
             onSelectUser={onSelectUser}
          />
        <BooleanComponent checkboxValue={"Reset module progress"} value ={action.resetModuleProgress} onValidateChange={onResetModule}/>
        <BooleanComponent checkboxValue={"Validate event criteria before executing this action"} value ={action.validateEventCriteria} onValidateChange={onValidateChange}/>
    </div>);
    return formControl;
}