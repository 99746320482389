import React from 'react';
import SelectSearch from 'react-select-search';
import Loader from 'react-loader-advanced';
import {AssignTo} from "./Filters/AssignTo";
import BooleanComponent from "./Filters/BooleanComponent";


export const JourneyForm=({action,
                              journeys,
                              loadingJourneys,
                              loadingSelfPacedSample,
                              sampleSelfpacedData,
                              onSelectJourney,
                              onValidateChange,
                              onSelectUser})=>{
    let journeyOptions = [];
    if (journeys.length === 0) {
        journeyOptions.push({
            name: "select journey",
            value: 1
        })
    }
    else {
        journeys.forEach((journey) => {
            journeyOptions.push({
                name: journey.journeyName,
                value: journey.journeyId
            });

        });
    }



    let formControl = (<div className={""}>
        <div className={" form-field"}>
            <div className={"label"}>Journey :</div>
            <div className={"component"}  >
                <Loader show={loadingJourneys} message={'fetching...'}>
                    <SelectSearch  key={new Date().valueOf()} onChange={onSelectJourney} options={journeyOptions}
                                   value={parseInt(action.journeyId)}
                                   placeholder="Select Journey"/>
                    <small>The journey to be assigned.
                    </small>
                </Loader>
            </div>
        </div>
        <AssignTo
            action={action}
            moduleName={"journey"}
            loadingSelfPacedSample={loadingSelfPacedSample}
            sampleSelfpacedData={sampleSelfpacedData}
            onSelectUser={onSelectUser}
        />
        <BooleanComponent checkboxValue={"Validate event criteria before executing this action"} value ={action.validateEventCriteria} onValidateChange={onValidateChange}/>
    </div>);
    return formControl;
}