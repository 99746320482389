import React from 'react';
import SelectSearch from 'react-select-search';

export const EvaluationCategoryActionFilter = ({action, onSelectEvaluationCategory}) => {
    let evaluationCategories = [
        // {
        //     name: "Manager",
        //     value: 4
        // },
        {
            name: "Reportee",
            value: 3
        },
        // {
        //     name: "Peer",
        //     value: 2
        // },
        {
            name: "Self",
            value: 1
        }
    ];
    return( <div className={" form-field"}>
        <div className={"label"}>Evaluation Category :</div>
        <div className={"component"} >
                <SelectSearch onChange={onSelectEvaluationCategory} options={evaluationCategories}
                              value={parseInt(action.evaluationCategory)}
                              placeholder="Select evaluation category"/>
                <small>The evaluation category for the feedback.</small>
        </div>
    </div>);
}