import React from 'react';
import SelectSearch from 'react-select-search';
import Loader from 'react-loader-advanced';

export const SkillActionFilter = ({action,skills, loadingSkills,moduleName, onSelectSkill}) => {
    let skillsOptions = [];
    skills.forEach((skill) => {
        skillsOptions.push({
            name: skill.skillName,
            value: skill.id
        })
    });

    return(<div className={" form-field"}>
         <div className={"label"}>Skill :</div>
         <div className={"component"} disabled={!action.selectedProgram}>
             <Loader show={loadingSkills} message={'fetching...'}>
                 <SelectSearch onChange={onSelectSkill} options={skillsOptions}
                               value={(loadingSkills || skills.length === 0) ? null : parseInt(action.selectedSkill)}
                               placeholder="Select Skill"/>
                 <small>The skill which contains the {moduleName}</small>
             </Loader>
         </div>
     </div>);
}