import React from 'react';
import SelectSearch from 'react-select-search';
import Loader from 'react-loader-advanced';

export const ProgramActionFilter = ({action,programs,loadingPrograms, moduleName,onSelectProgram}) => {
    let programOptions = [];
    programs.forEach((program) => {
        programOptions.push({
            name: program.programName,
            value: program.id
        })
    });
    return (<div className={" form-field"}>
        <div className={"label"}>Program :</div>
        <div className={"component"}>
            <Loader show={loadingPrograms} message={'fetching...'}>
                <SelectSearch onChange={onSelectProgram} options={programOptions}
                              value={loadingPrograms ? null : parseInt(action.selectedProgram)}
                              placeholder="Select Program"/>
                <small>The program which contains the {moduleName}</small>
            </Loader>
        </div>
    </div>);
}