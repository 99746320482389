import React from 'react';
import SelectSearch from 'react-select-search';
import Loader from 'react-loader-advanced';

export const ModuleActionFilter = ({action,modules,moduleName, loadingModules, onSelectModule}) => {
    let modulesOptions = [];
    if (modules.length === 0) {
        modulesOptions.push({
            name: "select module",
            value: 1
        })
    }
    else {
        modules.forEach((module) => {
            modulesOptions.push({
                name: module.moduleName,
                value: module.id
            });

        });
    }

    return( <div className={" form-field"}>
        <div className={"label"}>Module :</div>
        <div className={"component"} disabled={!action.selectedSkill}>
            <Loader show={loadingModules} message={'fetching...'}>
                <SelectSearch onChange={onSelectModule} options={modulesOptions}
                              value={modules.length ? parseInt(action.moduleId) : null}
                              placeholder="Select Module"/>
                <small>The {moduleName} to be assigned.</small>
            </Loader>
        </div>
    </div>);
}