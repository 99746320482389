import React from 'react';
import SelectSearch from 'react-select-search';
import Loader from 'react-loader-advanced';

export const EvaluationActionFilter = ({action,evaluations, loadingEvaluations, onSelectEvaluation}) => {
    let evaluationOptions = [];
    if (evaluations.length === 0) {
        evaluationOptions.push({
            name: "select evaluation",
            value: 1
        })
    }
    else {
        evaluations.forEach((evaluation) => {
            evaluationOptions.push({
                name: evaluation.evaluationName,
                value: evaluation.evaluationId
            });

        });
    }
    let evaluationName =   "Evaluation Name:";
    let evaluationDescription =   "The evaluation name to be assigned for an evaluator";

    if(! action.assign360Evaluation()){
        evaluationName ="Survey Name:";
        evaluationDescription = "The survey to be assigned for a user";
    }

    return( <div className={" form-field"}>
        <div className={"label"}>{evaluationName}</div>
        <div className={"component"} >
            <Loader show={loadingEvaluations} message={'fetching...'}>
                <SelectSearch onChange={onSelectEvaluation} options={evaluationOptions}
                              value={parseInt(action.evaluationId)}
                              placeholder="Select Feedback"/>
                <small>{evaluationDescription}</small>
            </Loader>
        </div>
    </div>);
}