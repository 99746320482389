import React from 'react';
import MultiSelect from "@khanacademy/react-multi-select";
import Loader from 'react-loader-advanced';

export const ParamsData = ({action, loadingSelfPacedSample, paramsLabel, sampleSelfpacedData,onSelectParamsData}) => {
    let userOptions = [];
    if (action.assignSPModule()) {
        if (Object.keys(sampleSelfpacedData).length) {
            for (let key in  sampleSelfpacedData) {
                userOptions.push({
                    label: key.replace(/_/g, " ").replace("UserId", "").replace("id", ""),
                    value:   key
                });
            }
        }
        else {
            userOptions.push({
                label: "Select From Trigger",
                value: 0
            });
        }
    }
    else {
        if (Object.keys(sampleSelfpacedData).length) {
            for (let key in  sampleSelfpacedData) {
                userOptions.push({
                    label: key.replace(/_/g, " ").replace("UserId", "").replace("id", ""),
                    value:  key
                });
            }
        }
        else {
            userOptions.push({
                label: "Select From Trigger",
                value: 0
            });
        }
    }
    return (<div className={"form-field"}>
        <div className={"label"}>{paramsLabel || "Post Data :"}</div>
        <div className={"component"}>
            <Loader show={loadingSelfPacedSample} message={'fetching...'}>
                <MultiSelect
                    overrideStrings={
                        {
                            selectSomeItems: "Select data from trigger",
                            allItemsAreSelected: "All data selected",
                            selectAll: "Select All",
                            search: "Search data",
                        }
                    }
                    searchPlaceholder={"Select Post Data"}
                    options={userOptions}
                    selected={action.webhook.paramsDataKey || []}
                    onSelectedChanged={onSelectParamsData}
                />
                <small>The data to be send to webhook url.</small>
            </Loader>

        </div>
    </div>)
}