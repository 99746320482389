import React from 'react';
import {deleteWorkflow, getWorkflows, saveWorkflow} from "../Service/Service";
import FormComponent from "../Components/FormComponent"
import Loader from 'react-loader-advanced';
import SelectSearch from 'react-select-search';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TabbedComponent from "../Components/TabbedComponent"
import {Checkbox} from "@disprz/components"
import Workflow from "../Models/Workflow";

class IntegrationView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedWorkflows: [],
            allWorkflows:[],
            activeWorkflowCount:0,
            inactiveWorkflowCount:0,
            selectedWorkflowId:null,
            apiToken: "",
            saveWorkflow:false,
            editTitle:false,
            loadData:false,
            workflowConfig:{},
            accessToken: "",
            loading: true,
            currentWorkflow: {},
            showSuccessDialog: false,
            loadingMessage:"Fetching data...",
            dialogTitle: "",
            dialogMessage: "",
            viewMode:1
        }
        sessionStorage.removeItem("apiToken");
        this.apiToken = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("message", this.getPostMessage);
        if(window.parent){
            window.parent.postMessage({
                type: "get-workflow-configuration"
            }, '*');
        }
        sessionStorage.setItem("accessToken", this.state.accessToken);
        this.getWorkflows(true);
    }
    getPostMessage =  (event) => {
        if (event.data) {
            var data = event.data;
            if (data.type === "access-token-response") {
                sessionStorage.setItem("accessToken", data.accessToken);
               this.setState({
                   accessToken:data.accessToken
               }, () =>{
                   this.getWorkflows();
                });
            }
            else if (data.type === "get-workflow-config-response") {
                sessionStorage.setItem("metricsMicroServiceAPIUrl",data.metricsMicroServiceAPIUrl);
                this.setState({
                    workflowConfig:data.workflowConfig
                }, function () {
                    window.parent.postMessage({
                        type: "access-token-request"
                    }, '*');
                });
            }
        }
    }

    getWorkflows = (onLoad, isCreate) => {
        return new Promise( (resolve, reject) => {
            if(this.state.accessToken){
                getWorkflows().then(response => {
                    var activeWorkflows = response.filter((workflow)=>{
                        return workflow.enabled;
                    });
                    var inactiveWorkflows = response.filter((workflow)=>{
                        return !workflow.enabled;
                    });
                    let currentWorkflow = {},viewMode = this.state.viewMode, selectedWorkflows = this.state.viewMode === 1 ? activeWorkflows : inactiveWorkflows;
                    if(viewMode === 1 && activeWorkflows.length > 0 ){
                        currentWorkflow = activeWorkflows[0]
                    }
                    else if( viewMode === 2 && inactiveWorkflows.length > 0){
                        selectedWorkflows = inactiveWorkflows;
                        currentWorkflow = inactiveWorkflows[0];
                        viewMode = 2;
                    }

                    let selectedWorkflow = this.getSelectedWorkflow(selectedWorkflows);
                    let selectedWorkflowId = this.state.selectedWorkflowId;
                    if(isCreate){
                        selectedWorkflow = inactiveWorkflows[inactiveWorkflows.length - 1];
                        selectedWorkflowId= selectedWorkflow.id;
                    }
                    this.setState({
                        loading: false,
                        activeWorkflowCount:activeWorkflows.length,
                        inactiveWorkflowCount:inactiveWorkflows.length,
                        selectedWorkflows: selectedWorkflows,
                        currentWorkflow:selectedWorkflow ? new Workflow(selectedWorkflow) : new Workflow(currentWorkflow),
                        selectedWorkflowId:selectedWorkflowId,
                        allWorkflows: response.length > 0 ? response : [],
                        viewMode: viewMode
                    },()=>{
                        resolve();
                    })
                });
            }
        });
    }

    render() {
        let workflowOptions =[];
        let workflowSelection ="";
        let form = "";
        let saveBtn ="";
        let workflowNameEdit ="";
        let noworkFlow = "";
        let sucessDialog = "";
        let addMoreAction = "";
        let deleteWorkflowButton ="";
        let activeButton = "";
        if(this.state.currentWorkflow && this.state.currentWorkflow.actions && this.state.currentWorkflow.actions.length){
            addMoreAction = (  <div className={"button add-more-btn"}>
                <button className={"app-button v1"} onClick={this.addMoreAction}>Add Action</button>
            </div>)
        }
        if(this.state.saveWorkflow){
            saveBtn =(  <div className={"button save-btn"}>
                <button className={"app-button v1"} onClick={this.updateWorkflow}>Save Workflow</button>
            </div>)
        }
        if (this.state.selectedWorkflows.length) {
            this.state.selectedWorkflows.forEach((workflow) => {
                workflowOptions.push({
                    name:workflow.workflowName,
                    value:workflow.id || workflow.tempId
                });
            }, this);
            if(workflowOptions.length > 1){
                workflowSelection = (<div className={"workflow-dropdown form-control"}>
                    <span className={"text"}>Select Workflow:</span>
                    <SelectSearch onChange={this.setWorkflow} options={workflowOptions}
                                  value={this.state.currentWorkflow.id || this.state.currentWorkflow.tempId}
                                  placeholder="Select Workflow"/>
                </div> );
            }
            let editInput =(<span className={"text"} >{this.state.currentWorkflow.workflowName}</span> )
            if(this.state.editTitle){
                editInput =(<input type={"text"} value={this.state.currentWorkflow.workflowName} onChange={this.setWorkflowTitle}/> );
            }
            workflowNameEdit = ( <div className={"workflow-title"}>
                {editInput}
                {!this.state.editTitle && <span onClick={this.editWorkflowTitle} className={"fa fa-edit"}></span>}
                {this.state.editTitle && <span onClick={this.saveWorkflowTitle} className={"fa fa-check"}></span>}
                {addMoreAction}
            </div>);
            form = (<FormComponent onActionChange={this.enableSaveButton}
                                   key={"form_" +this.state.selectedWorkflows.length}
                                   loadData={this.state.loadData}
                                   onActionPush = {this.updateState}
                                   currentWorkflow={this.state.currentWorkflow}/>);

        }
        else if (sessionStorage.getItem("accessToken") !== null) {
            let nowWorkflowMessage= "There are no workflows configured yet";
            if(this.state.selectedWorkflows.length === 0){
                if(this.state.viewMode === 1){
                    nowWorkflowMessage= "There are no active workflows configured yet.";
                }
                else{
                    nowWorkflowMessage= "There are no in-active workflows.";
                }
            }
            else if(this.state.allWorkflows.length === 0){
                nowWorkflowMessage= "There are no workflows configured yet";
            }
            noworkFlow = (<div className={"noworkflow"}>{nowWorkflowMessage}</div>)
        }
        if (sessionStorage.getItem("accessToken") !== null) {

            if(this.state.currentWorkflow && this.state.currentWorkflow.id){
                deleteWorkflowButton = (<div className={"button delete-workflow-button"}>
                    <button className={"app-button v1 delete"} onClick={this.deleteWorkflow}>Delete Workflow</button>
                </div>);
                activeButton = ( <Checkbox isChecked ={false} checkBoxLabel={!this.state.currentWorkflow.enabled ? "Enable":"Disable"}
                                           onChange={this.activateWorkflow}></Checkbox>)
            }

        }

        if (this.state.showSuccessDialog) {
            sucessDialog = (
                <div className={"dialog-overlay"}>
                    <div className={"dialog-control"}>
                        <div className={"dialog-wrapper"}>
                            <div className={"title"}>
                                {this.state.dialogTitle}
                            </div>
                            <div className={"message"}>
                                {this.state.dialogMessage}
                            </div>
                            <div className={"buttons"}>
                                <button className={"app-button v1"}
                                        onClick={this.closeDialog}>
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        let key = this.state.currentWorkflow ? this.state.currentWorkflow.id ? this.state.currentWorkflow.id : this.state.currentWorkflow.tempId : new Date().valueOf();
        return (
            <div key={key || new Date().valueOf()} className={"container-fluid"}>
                <Loader className={"main-loader"} show={this.state.loading} message={this.state.loadingMessage}>
                    <div className={"workflow-selection workflow-header form-control col-xs-12"}>
                        {deleteWorkflowButton}
                        {activeButton}
                    </div>

                    <div className={"wrapper"}>
                        {this.renderTabs()}
                        {workflowSelection}
                        {workflowNameEdit}
                        {form}
                        {saveBtn}
                        {noworkFlow}
                    </div>

                </Loader>
                {sucessDialog}
            </div>
        )
    }
    renderTabs=()=>{
        let tabs = [
            {
                name: "Active Workflows",
                active: (this.state.viewMode === 1),
                data: 1,
                badgeCount:this.state.activeWorkflowCount
            },
            {
                name: "In-Active Workflows",
                active: (this.state.viewMode === 2),
                data: 2,
                badgeCount:this.state.inactiveWorkflowCount
            }
        ]
        return (
            <TabbedComponent addNewWorkflow={this.addNewWorkflow}
                ctrCls="col-xs-12   col-sm-12    new-tabs-container"
                tabs={tabs} onTabSelection={this.changeTab}/>);
    }
    getSelectedWorkflows = (viewMode)=>{
        let selectedWorkflows = [];
        if(viewMode === 1){
            selectedWorkflows=   this.state.allWorkflows.filter((workflow)=>{
                return workflow.enabled;
            });
        }
        else{
            selectedWorkflows=   this.state.allWorkflows.filter((workflow)=>{
                return !workflow.enabled;
            });
        }
        return selectedWorkflows;
    }
    changeTab =  (tabIndex, viewMode) => {
        let selectedWorkflows =  this.getSelectedWorkflows(viewMode);
        let selectedWorkflow = this.getSelectedWorkflow(selectedWorkflows);
        this.setState({
            viewMode: viewMode,
            selectedWorkflows:selectedWorkflows,
            currentWorkflow:new Workflow(selectedWorkflow),
            saveWorkflow:false
        });
    };
    getSelectedWorkflow=(selectedWorkflows)=>{
        let selectedWorkflow = null;
        if(this.state.selectedWorkflowId){
            selectedWorkflow = selectedWorkflows.find((workflow)=>{
                var id = workflow.id || workflow.tempId;
                return id === this.state.selectedWorkflowId;
            });
        }
        if(selectedWorkflows && !selectedWorkflow){
            selectedWorkflow = selectedWorkflows[0]
        }
        return selectedWorkflow;
    }
    closeDialog = () => {
        this.setState({
            showSuccessDialog: false
        })
    }
    addMoreAction = () =>{
        let currentWorkflow = this.state.currentWorkflow;
        currentWorkflow.actions.push( {
            "actionName": "assignSelfpacedModule",
            "selectedProgram": 0,
            "selectedSkill": 0,
            "actionDisplayName": "Assign SelfPaced Module",
            "delayFor": 0,
            "emailData": {
                "toAddress": "",
                "subject": "",
                "body": ""
            },
            "moduleId": 0,
            "userId": 0,
            "journeyId": 0
        });
        this.setState({
            currentWorkflow:new Workflow(currentWorkflow),
            loadData:true
        });
    }
    activateWorkflow = () =>{
        let currentWorkflow = this.state.currentWorkflow;
        let message = {loadingMessage:"Disabling your workflow...", successMessage:"Your workflow is disabled."};
        if(!currentWorkflow.enabled){
              message = {loadingMessage:"Enabling your workflow...", successMessage:"Your workflow is enabled."};
        }
        currentWorkflow.enabled = !currentWorkflow.enabled;


        this.setState({
            loading:true,
            loadingMessage:"Activating workflow...",
            selectedWorkflowId:null,
            currentWorkflow:new Workflow(currentWorkflow)
        }, ()=>{
            this.updateWorkflow(null,message);
        });

    }
    deleteWorkflow = (index) => {
        confirmAlert({
            title: 'Alert',
            message: 'Are you want to delete this Workflow?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({
                            loading:true,
                            loadingMessage:"Deleting workflow..."
                        })
                        deleteWorkflow(this.state.currentWorkflow.id).then(save => {
                            if (save) {
                                setTimeout(()=>{
                                    this.getWorkflows(true);
                                    this.setState({
                                        loading: false,
                                        showSuccessDialog: true,
                                        dialogTitle: "Success",
                                        loadingMessage:"Fetching data...",
                                        dialogMessage: "Your workflow deleted successfully."
                                    }, ()=>{
                                    });
                                },2000)

                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    }
    updateState = (saveWorkflow)=>{
        let save = this.state.saveWorkflow;
        if(saveWorkflow === false){
            save = false;
        }
        this.setState({saveWorkflow:save});
    }
    enableSaveButton = (value) =>{
        this.setState({
            saveWorkflow:value,
            loadData:false
        })
    }
    setWorkflowTitle = (event, val) =>{
        let currentWorkflow =this.state.currentWorkflow;
        currentWorkflow.workflowName = event.currentTarget.value;
        this.setState({
            currentWorkflow:new Workflow(currentWorkflow)
        })
    }
    saveWorkflowTitle = ()=>{
        let save = false;
        this.state.currentWorkflow.actions.forEach(function (action) {
            if(!action.assignDelay()){
                save = true;
            }
        })
        this.setState({
            editTitle:false,
            saveWorkflow:save
        })
    }
    editWorkflowTitle=() =>{
        this.setState({
            editTitle:true
        })
    }
    getGuid=  () =>{
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }
    addNewWorkflow = () => {
        let newWorkflow = {
            "trigger": "userCreated",
            "workflowName":"New Workflow",
            "tempId": this.getGuid(),
            "triggerName": "New user created",
            "enabled":false,
            "triggerFilters": {
                "moduleIds": [],
                "skillIds": [],
                "journeyIds": [],
                "metricTriggers":{
                    "and": [],
                    "or": []
                },
                "programIds": [],
                "evaluationIds":[],
                "fieldId1":"",
                "fieldValue1":[],
                "fieldId2":"",
                "fieldValue2":[],
                "fieldId3":"",
                "fieldValue3":[],
            },
            "actions": []
        }
        let workFlows = this.state.allWorkflows;
        workFlows.push(new Workflow(newWorkflow));

        this.setState({
            allWorkflows: workFlows,
            saveWorkflow:false,
            viewMode:2,
            currentWorkflow: new Workflow(newWorkflow),
            selectedWorkflowId: newWorkflow.id
        },()=>{
            let selectedWorkflows =  this.getSelectedWorkflows(this.state.viewMode);
            this.setState({
                selectedWorkflows:selectedWorkflows
                }
            )
        })
    }
    setApiToken = (value) => {
        this.setState({
            apiToken: value
        })
    }
    updateWorkflow = (event,message) => {
        // let workFlows = this.state.workflows;
        // workFlows[index] = workflow;
        // this.state.workflows[index] = workflow;
        this.setState({
            loading:true,
            loadingMessage: message ? message.loadingMessage : "Saving workflow..."
        })
        let create = false;
        if(!this.state.currentWorkflow.id){
            create = true;
        }
        saveWorkflow(this.state.currentWorkflow).then(save => {
            if (save) {
                this.getWorkflows(false,create);
                this.setState({
                    showSuccessDialog: true,
                    dialogTitle: "Success",
                    loading:false,
                    saveWorkflow:false,
                    loadingMessage:"Fetching data...",
                    dialogMessage:message ? message.successMessage : "Your workflow saved successfully."
                })
            }
        })

    }
    setWorkflow = (selectedWorkflow) => {
        let currentWorkflow = this.state.selectedWorkflows.find((workflow)=>{
            if(workflow.id){
                return workflow.id === selectedWorkflow.value
            }
            else{
                return workflow.tempId === selectedWorkflow.value
            }
        })

        this.setState({
            currentWorkflow: new Workflow(currentWorkflow),
            selectedWorkflowId: selectedWorkflow.value,
            loadData:true
        })
    }
}

export default IntegrationView
