import React from "react";
import SelectSearch from "react-select-search";
import {dateFormatOptions} from "../../../Helpers/TenureFilterHelper";

const TenureFilters = ({
                           workflow,
                           udfFieldOptions,
                           onChangeTenureDateField,
                           onChangeTenureDateFormat,
                           onChangeTenureValue
                       }) => {

    if (!workflow.isUserCompletesTenureTrigger()) {
        return null;
    }

    const {tenureDateField, tenureDateFormat, tenureValue = ''} = workflow.triggerFilters;

    const renderDateFieldForTenure = () => {
        return (
            <div className={"form-field"}>
                <div className="label"> Date Field For Tenure : <small className={'mandatory'}>*</small></div>
                <div className="component">
                    <SelectSearch onChange={onChangeTenureDateField}
                                  options={udfFieldOptions}
                                  value={tenureDateField}
                                  placeholder="Select Date Field"/>
                    <small>
                        Select User attribute / user defined field basis which the tenure should be accessed
                    </small>
                </div>
            </div>
        )
    }

    const renderDateFieldFormat = () => {
        return (
            <div className={"form-field"}>
                <div className="label"> Date Field Format : <small className={'mandatory'}>{` * `}</small></div>
                <div className="component">
                <input placeholder="MM-dd-yyyy"  type={"text"} value={tenureDateFormat} onChange={onChangeTenureDateFormat}/>
                    <small>
                        Provide date format of the values on the Tenure Date Field (UDF) (Eg. MM-dd-yyyy)
                    </small>
                </div>
            </div>
        )
    }

    const renderTenureValueField = () => {
        return (
            <div className={"form-field"}>
                <div className="label"> Tenure Value (in days) : <small className={'mandatory'}>*</small></div>
                <div className="component">
                    <input type={"number"}
                           value={tenureValue}
                           placeholder={"Enter Tenure Value"}
                           onChange={onChangeTenureValue}
                           maxLength={3}
                           name={"tenureValue"}/>
                    <small>
                        The day number since the Tenure Date Field, When we want the capture the event. (Eg. A tenure
                        value of 60, will capture the 60th day since the Tenure Date Field)
                    </small>
                </div>
            </div>
        )
    }
    return (
        <>
            {renderDateFieldForTenure()}
            {renderDateFieldFormat()}
            {renderTenureValueField()}
        </>
    )
};

export default TenureFilters;
