import Actions from "./Actions";

export default class Triggers {
    constructor(config = {}) {
        this.trigger = config.trigger;
        this.workflowName = config.workflowName;
        this.triggerName = config.triggerName;
        this.actions = config.actions || [];
        this.id = config.id;
        this.tempId = config.tempId;
        this.triggerFilters = config.triggerFilters;
        this.enabled = config.enabled;
        this.init();
    }

    init = () => {
        if (this.actions.length) {
            this.actions = this.actions.map((action) => {
                return new Actions(action);
            })
        }
    }

    isJourneyTrigger() {
        return this.trigger === "userJourneyCompleted";
    }

    isUserCreatedTrigger() {
        return this.trigger === "userCreated";
    }

    isUserAttributeChangeTrigger() {
        return this.trigger === "userAttributeChange";
    }

    isKPIValueAddTrigger() {
        return this.trigger === "kpiMetricValueAdded" || this.trigger === "kpiMetricValueAdded_legacy";
    }

    isKPIValueAddTriggerLegacy() {
        return this.trigger === "kpiMetricValueAdded_legacy";
    }

    isSPModuleCompletedTrigger() {
        return this.trigger === "selfPacedModuleCompleted";
    }

    isCRModuleCompletedTrigger() {
        return this.trigger === "classroomModuleCompleted";
    }

    isStandaloneSurveyCompletedTrigger() {
        return this.trigger === "standaloneSurveySubmitted";
    }

    is360EvaluationCompletedTrigger() {
        return this.trigger === "360EvaluationSubmitted";
    }

    isUserCompletesTenureTrigger() {
        return this.trigger === "userTenureCompleted";
    }
}
