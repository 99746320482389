import React from 'react';
import SelectSearch from 'react-select-search';
import Loader from 'react-loader-advanced';

export const BatchActionFilter = ({action,moduleBatches,moduleName, loadingBatches, onSelectBatch}) => {
    let batchOptions = [];
    if (moduleBatches.length === 0) {
        batchOptions.push({
            name: "select batch",
            value: 1
        })
    }
    else {
        moduleBatches.forEach((batch) => {
            batchOptions.push({
                name: batch.batchName,
                value: batch.id
            });

        });
    }

    return( <div className={" form-field"}>
        <div className={"label"}>Batch :</div>
        <div className={"component"} disabled={!action.selectedSkill}>
            <Loader show={loadingBatches} message={'fetching...'}>
                <SelectSearch onChange={onSelectBatch} options={batchOptions}
                              value={parseInt(action.batchId)}
                              placeholder="Select Module"/>
                <small>The Batch in the selected Classroom Module to assign the user to.
                </small>
            </Loader>
        </div>
    </div>);
}