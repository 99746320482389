import React from 'react';
import MultiSelect from "@khanacademy/react-multi-select";
import Loader from 'react-loader-advanced';


export const SurveyFilters = ({workflow, evaluations, loadingEvaluations, onEvaluationSelect}) => {
    if (!(workflow.isStandaloneSurveyCompletedTrigger() || workflow.is360EvaluationCompletedTrigger())) {
        return (null)
    }
    else {
        let evaluationOptions = [];
        evaluations.forEach((evaluation) => {
            if(workflow.triggerFilters.evaluationIds.includes(evaluation.evaluationId)){
                evaluationOptions.splice(0,0,{
                    label: evaluation.evaluationName,
                    value: evaluation.evaluationId 
                });
            }
            else{
                evaluationOptions.push({
                    label: evaluation.evaluationName,
                    value: evaluation.evaluationId
                })
            }

        }, this);

        return (<div className={"form-field"}>
            <div className="label"> Evaluation : <small>(Optional)</small></div>
            <div className="component">
                <Loader show={loadingEvaluations} message={'fetching...'}>
                    <MultiSelect
                        overrideStrings={
                            {
                                selectSomeItems: "Select Evaluation",
                                allItemsAreSelected: "All Evaluations are Selected",
                                selectAll: "Select All Evaluation",
                                search: "Search Evaluation",
                            }
                        }
                        searchPlaceholder={"Select Evaluation"}
                        options={evaluationOptions}
                        selected={workflow.triggerFilters.evaluationIds}
                        onSelectedChanged={onEvaluationSelect}
                    />
                    <small>Filter by survey. Multiple values work like an OR condition.
                    </small>
                </Loader>
            </div>
        </div>)
    }
}
