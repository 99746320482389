import React from 'react';
import MultiSelect from "@khanacademy/react-multi-select";
import Loader from 'react-loader-advanced';


export const SkillsFilter = ({workflow, skills, loadingSkills, onSkillSelect}) => {
    if (workflow.isJourneyTrigger() || workflow.isUserCreatedTrigger() || workflow.isUserAttributeChangeTrigger() || workflow.isKPIValueAddTrigger() || workflow.isStandaloneSurveyCompletedTrigger() || workflow.is360EvaluationCompletedTrigger() || workflow.isUserCompletesTenureTrigger()) {
        return (null)
    }
    else {
        let skillsOptions = [];
        skills.forEach((skill) => {
            if(workflow.triggerFilters.skillIds.includes(skill.id)){
                skillsOptions.splice(0,0,{
                    label: skill.skillName,
                    value: skill.id
                });
            }
            else{
                skillsOptions.push({
                    label: skill.skillName,
                    value: skill.id
                })
            }

        }, this);

        return (<div className={"form-field"}>
            <div className="label"> Skills : <small>(Optional)</small></div>
            <div className="component">
                <Loader show={loadingSkills} message={'fetching...'}>
                    <MultiSelect
                        overrideStrings={
                            {
                                selectSomeItems: "Select Skills",
                                allItemsAreSelected: "All Skills are Selected",
                                selectAll: "Select All Skills",
                                search: "Search Skills",
                            }
                        }
                        disabled={!workflow.triggerFilters.programIds.length}
                        searchPlaceholder={"Select Skills"}
                        options={skillsOptions}
                        selected={workflow.triggerFilters.skillIds}
                        onSelectedChanged={onSkillSelect}
                    />
                    <small>Filter by the Skill that the Module belongs to (e.g., Data Visualization). Multiple values work like an OR condition.
                    </small>
                </Loader>
            </div>
        </div>)
    }
}
