import React from 'react';
import {Checkbox} from "@disprz/components"

export const BooleanComponent = ({action, checkboxValue, value, onValidateChange}) => {

    return (<div className={"form-field validate-trigger"}>
        <div className={"component"}>
            <Checkbox checkBoxLabel={checkboxValue}
                      value={value}
                      onChange={onValidateChange}/>
        </div>
    </div>)
}
export default  BooleanComponent
