import Webhook from "./webhookAction";

export default  class Actions{
    constructor(config={}) {
        this.actionName = config.actionName;
        this.actionDisplayName = config.actionDisplayName;
        this.moduleId = config.moduleId;
        this.selectedProgram = config.selectedProgram;
        this.selectedSkill =config.selectedSkill;
        this.batchId = config.batchId;
        this.userId = config.userId;
        this.journeyId = config.journeyId;
        this.emailData=config.emailData;
        this.evaluationRunId = config.evaluationRunId;
        this.evaluationId=config.evaluationId;
        this.evaluators = config.evaluators || [];
        this.delayFor = config.delayFor;
        this.delayExecuteAt = config.delayExecuteAt;
        this.validateEventCriteria = config.validateEventCriteria || false;
        this.resetModuleProgress = config.resetModuleProgress || false;
        this.evaluatee=config.evaluatee;
        this.webhook = config.webhook ?  new Webhook(config.webhook) : new Webhook();
        this.evaluationCategory=config.evaluationCategory;
        this.delayUnit =config.delayUnit;
        this.actionId= config.actionId;
    }
    assignCRModule(){
        return this.actionName.toLowerCase() === "assignclassroommodule";
    }
    assignSPModule(){
        return this.actionName.toLowerCase() === "assignselfpacedmodule";
    }
    assignJourney(){
        return this.actionName.toLowerCase() === "assignjourney";
    }
    assign360Evaluation(){
        return this.actionName.toLowerCase() === "assignevaluationsurvey";
    }
    isSelfEvaluation(){
        return this.assign360Evaluation() && this.evaluationCategory === 1;
    }
    triggerWebhook(){
        return this.actionName.toLowerCase() === "webhooktrigger";
    }
    assignEvaluation(){
        return (this.actionName.toLowerCase() === "assignevaluationsurvey" ||this.actionName.toLowerCase() === "assignstandalonesurvey")
    }
    assignStandAloneEvaluation(){
        return (this.actionName.toLowerCase() === "assignstandalonesurvey")
    }
    sendEmail(){
        return this.actionName.toLowerCase() === "sendmail";
    }
    assignDelay(){
        return this.actionName.toLowerCase() === "delay";
    }
}